import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { find, map, size, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import ContactModal from 'common/components/ContactModal/ContactModal';
import { startOfToday, subDays } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { customerProductDetailURL } from 'navigation';
import useGetRecentProducts from 'api/customer/useGetRecentProducts';
import useGetRecentSearches from 'api/customer/useGetRecentSearches';
import useGetWebActivityContacts from 'api/customer/useGetWebActivityContacts';
import type { ActivityFiltersButton } from 'models/ActivityModels';
import type { SnapshotsURLParams } from 'models/Search';
import type {
  WebActivityTabType,
  WebActivityContactModalProps,
} from 'models/WebActivity';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { getSnapshotEyebrow } from 'utils/search';
import ActivityFilters from 'components/Activities/ActivityFilters/ActivityFilters';
import CardMini from 'components/CardMini/CardMini';
import Header from 'components/Header/Header';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import ProductsCard from 'components/ProductsCard/ProductsCard';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import type { SegmentTabOptionProps } from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './WebActivity.module.scss';

const options: SegmentTabOptionProps[] = [
  { key: 'cartAdditionsGreaterThan', text: 'common:addedCart' },
  { key: 'viewsGreaterThan', text: 'common:viewed' },
  { key: 'ordersGreaterThan', text: 'common:bought' },
];

const WebActivity = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<WebActivityTabType>(
    'cartAdditionsGreaterThan'
  );
  const [filterItem, setFilterItem] = useState<ActivityFiltersButton>();
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [contactModalData, setContactModalData] =
    useState<WebActivityContactModalProps>();
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { url } = useRouteMatch();
  const startDate = formatDate(
    subDays(startOfToday(), 30),
    DateFormatEnum.activitiesDateRangeAPI
  );
  const endDate = formatDate(
    startOfToday(),
    DateFormatEnum.activitiesDateRangeAPI
  );

  const {
    contacts,
    isLoading: isContactsLoading,
    refetch: refetchWAContacts,
  } = useGetWebActivityContacts({
    miLoc,
    id,
    startDate,
    endDate,
  });

  const {
    recentWebSearches,
    customerData,
    hasError: searchHasError,
    showLoader: searchShowLoader,
    isEmptyResponse: searchIsEmptyResponse,
    refetch: refetchRecentSearches,
  } = useGetRecentSearches({
    miLoc,
    id,
    userID: filterItem?.id,
    startDate,
    endDate,
  });

  const {
    recentProductSearches,
    error: recentProductError,
    hasError: productsHasError,
    showLoader: productsShowLoader,
    isEmptyResponse: productsIsEmptyResponse,
    enableInfiniteScroll: productsEnableInfiniteScroll,
    fetchNextPage: productsFetchNextPage,
    refetch: refetchRecentProducts,
  } = useGetRecentProducts({
    miLoc,
    id,
    productType: selectedTab,
    userID: filterItem?.id,
    startDate,
    endDate,
  });

  const activityFilterButtons: ActivityFiltersButton[] = useMemo(
    () =>
      map(contacts, ({ name, userId }) => ({
        name,
        id: userId,
        disabled: false,
        showCounter: false,
      })),
    [contacts]
  );

  const { t } = useTranslation(namespaces.snapshot);

  const translateOptions = (option: SegmentTabOptionProps) => {
    const tOption = option;
    tOption.text = t(option.text);
    return option;
  };
  const selectedTabName = toString(
    find(options.map(translateOptions), { key: selectedTab })?.text
  );

  return (
    <IonPage className={classes.WebActivity} data-testid="WebActivity-page">
      <Header
        title={t('webActivity')}
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        withBackButton
        testid="webActivity"
      />
      <IonContent>
        <Refresher
          slot="fixed"
          className={classes.refresher}
          hidden
          onRefresh={async () => {
            await Promise.all([
              refetchWAContacts?.call(null),
              refetchRecentSearches?.call(null),
              refetchRecentProducts?.call(null),
            ]);
          }}
          disabled={searchShowLoader || productsShowLoader || isContactsLoading}
          testid="webActivity"
        />
        {size(contacts) > 0 && (
          <div
            className={classes.filterWrapper}
            data-testid="web-activity-filters"
          >
            <ActivityFilters
              filterItem={filterItem}
              setFilterItem={setFilterItem}
              buttons={activityFilterButtons}
              testid="web-activity-filters"
            />
          </div>
        )}
        <div className={classes.webActivityContainer}>
          <div className={classes.searchesContainer}>
            <Text
              className={classes.title}
              variant="underlined-title-section"
              text={t('recentSearch')}
            />
            <TitleLine className={classes.titleLine} />
            {!searchShowLoader && !searchHasError && !searchIsEmptyResponse && (
              <div
                className={classes.cardMiniWrapper}
                data-testid="web-activity-searches"
              >
                {map(recentWebSearches, (item, index) => (
                  <CardMini
                    key={index}
                    text={item.searchPhrase}
                    description={item.contactName}
                    aging={item.creationTimestamp}
                    onClick={() => {
                      setContactModalData({
                        searchPhrase: item.searchPhrase,
                        customerData,
                        contactData: find(contacts, {
                          name: item.contactName,
                        }),
                      });
                      setIsOpenContactModal(true);
                    }}
                    testid="recent-searches-card"
                  />
                ))}
                <ContactModal
                  isOpen={isOpenContactModal}
                  setIsOpen={setIsOpenContactModal}
                  name={contactModalData?.contactData?.name}
                  testid="contact-modal-web-activity"
                  email={contactModalData?.contactData?.email}
                  phoneNumber={contactModalData?.contactData?.phone}
                />
              </div>
            )}
            <Loader
              className={classes.loader}
              text={t('common:loading')}
              isOpen={searchShowLoader}
              testid="loading"
            />
            {searchIsEmptyResponse && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('noResultRecentSearch')}
              />
            )}
            {searchHasError && (
              <WarningMessage
                className={classes.warningMessage}
                title={t('errorRecentSearch')}
                body={getErrorMessage(recentProductError)}
              />
            )}
          </div>
          <div>
            <div className={classes.productsContainer}>
              <Text
                className={classes.title}
                variant="underlined-title-section"
                text={t('recentProducts')}
              />
              <TitleLine className={classes.titleLine} />
              <SegmentTabs
                variant="whiteTabs"
                className={classes.webActivityTabs}
                selectedClassName={classes.webActivitySelectedTab}
                options={options.map(translateOptions)}
                value={selectedTab}
                setValue={(v: string) =>
                  setSelectedTab(v as WebActivityTabType)
                }
                testid="web-activity-tabs"
              />
              <div className={classes.productWrapper}>
                {map(recentProductSearches, (item, index) => (
                  <ProductsCard
                    key={index}
                    className={classes.productCard}
                    text={item.productName}
                    aging={item.creationTimestamp}
                    description={item.contactName}
                    avatar={item.imageUrl}
                    href={concatRoutes(
                      url,
                      customerProductDetailURL(item.itemNo)
                    )}
                    testid="test"
                  />
                ))}
              </div>
            </div>
            <Loader
              className={classes.loader}
              text={t('common:loading')}
              isOpen={productsShowLoader}
              testid="loading"
            />
            {productsIsEmptyResponse && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('noResultsProducts', { type: selectedTabName })}
              />
            )}
            {productsHasError && (
              <WarningMessage
                className={classes.warningMessage}
                title={t('errorResultsProducts', { type: selectedTabName })}
                body={getErrorMessage(recentProductError)}
              />
            )}
            {!productsShowLoader && (
              <InfiniteScroll
                disabled={!productsEnableInfiniteScroll}
                onLoadMore={productsFetchNextPage}
                testid="infinite-scroll"
              />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WebActivity;
