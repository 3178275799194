import { isEmpty } from 'lodash';
import { and, or } from 'common/utils/logicHelpers';
import { differenceInCalendarDays, isToday } from 'date-fns';
import { parseDate } from 'utils/date';

export enum LoggedInStatusEnum {
  active = 'active',
  away = 'away',
  inactive = 'inactive',
}

export const employeeStatus = (lastLoggedIn: string): LoggedInStatusEnum => {
  const formattedLastLoggedIn = parseDate(lastLoggedIn);
  const today = new Date();
  let status = LoggedInStatusEnum.active;
  const differenceInDays = differenceInCalendarDays(
    today,
    formattedLastLoggedIn
  );

  if (and(differenceInDays <= 30, !isToday(formattedLastLoggedIn))) {
    status = LoggedInStatusEnum.away;
  }
  if (or(differenceInDays > 30, isEmpty(lastLoggedIn))) {
    status = LoggedInStatusEnum.inactive;
  }

  return status;
};
