import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { isNil, pick, values } from 'lodash';
import useGetAccessControl from 'api/user/useGetAccessControl';
import type { RootState } from 'store/reducers';
import useAccessControlsEnv from './useAccessControlsEnv';

export enum AccessControlType {
  viewCustomers,
  viewReports,
  camUserReports,
  viewDocuments,
  editDocuments,
  viewItemReplenishment,
  downloadItemReplenishment,
  editItemReplenishment,
  viewIssueProcessing,
  editIssueProcessing,
  viewSalesPlays,
  viewLocationPick12,
  approvePriceOverride,
  ApproveSourcingOverride,
  workOrders,
  editIndustryCodes,
  CostSavingsAccessControls,
  AddCostSavingsAccessControls,
  AddCostSavingsAccessControlsForCorpAccount,
  EditOrdersAccessControls,
  ViewOrdersAccessControls,
  ResetOcnLinePrice,
  InventoryAccessControl,
  InventoryCreateGroupControls,
  InventoryCreateCountControls,
  ViewUnbilledReport,
  editOcnPrice,
}

const CustomerSnapshotAccessControls = ['v_CUST_MANAGEMENT'];
export const ReportsAccessControls = ['v_SALES_COMPARISON', 'v_VIEW_SALES_INQ'];
const DocumentsAccessControls = ['DOCS_INQUIRY'];
const DocumentsUpdateControls = ['DOCS_UPDATE'];
const ItemReplenishmentControls = ['MI_SR_POU_REPLENISHMENT_INQ'];
const ItemReplenishmentDownload = ['MI_SR_POU_REPLENISHMENT_UPD'];
const EditItemReplenishmentControls = ['MI_SR_POU_RT_QTY_OVERRIDE_UPD'];
const IssueProcessingControls = ['SR_ISSUE_PROCESSING_INQ'];
const SalesPlaysAccessControls = ['LEADS_OPPORTUNITY_INQ'];
const ApprovePriceOverride = ['OM_PRICE_OVERRIDE_APPROVAL'];
const ApproveSourcingOverride = ['PR_SOURCING_OVERRIDE_UPD'];
const WorkOrdersAccessControls = ['SC_WORK_ORDER_INQ'];
const EditIndustryCodesControls = ['CM_BRANCH_UPD', 'CM_BRMGR_UPD'];
const CostSavingsAccessControls = ['CM_COST_SAVINGS_INQ'];
const AddCostSavingsAccessControls = [
  'CM_COST_SAVINGS_ADD',
  'CM_COST_SAVINGS_UPD',
  'OP_INVOICE_UPD',
  'OP_ORDER_ENTY_UPD',
];
const AddCostSavingsAccessControlsForCorpAccount = ['CM_COST_SAVINGS_ADD'];
export const CamUserReport = ['v_SALES_ANALYSIS'];

// Sets overrides for access control flags application wide
const accessControlOverrides = {
  v_CUST_MANAGEMENT: 'Y',
};

const accessControls = (accessControl: AccessControlType) => {
  switch (accessControl) {
    case AccessControlType.viewCustomers:
      return CustomerSnapshotAccessControls;
    case AccessControlType.viewReports:
      return ReportsAccessControls;
    case AccessControlType.camUserReports:
      return CamUserReport;
    case AccessControlType.viewDocuments:
      return DocumentsAccessControls;
    case AccessControlType.editDocuments:
      return DocumentsUpdateControls;
    case AccessControlType.viewItemReplenishment:
      return ItemReplenishmentControls;
    case AccessControlType.downloadItemReplenishment:
      return ItemReplenishmentDownload;
    case AccessControlType.editItemReplenishment:
      return EditItemReplenishmentControls;
    case AccessControlType.viewIssueProcessing:
    case AccessControlType.editIssueProcessing:
      return IssueProcessingControls;
    case AccessControlType.viewSalesPlays:
      return SalesPlaysAccessControls;
    case AccessControlType.viewLocationPick12:
      return ReportsAccessControls;
    case AccessControlType.approvePriceOverride:
      return ApprovePriceOverride;
    case AccessControlType.ApproveSourcingOverride:
      return ApproveSourcingOverride;
    case AccessControlType.workOrders:
      return WorkOrdersAccessControls;
    case AccessControlType.editIndustryCodes:
      return EditIndustryCodesControls;
    case AccessControlType.CostSavingsAccessControls:
      return CostSavingsAccessControls;
    case AccessControlType.AddCostSavingsAccessControls:
      return AddCostSavingsAccessControls;
    case AccessControlType.AddCostSavingsAccessControlsForCorpAccount:
      return AddCostSavingsAccessControlsForCorpAccount;
    case AccessControlType.EditOrdersAccessControls:
      return ['OP_ORDER_ENTRY_UPD'];
    case AccessControlType.ViewOrdersAccessControls:
      return ['OP_ORDER_ENTRY_INQ', 'OP_ORDER_ENTRY_UPD'];
    case AccessControlType.InventoryAccessControl:
      return ['CT_INQ'];
    case AccessControlType.InventoryCreateGroupControls:
      return ['CT_CREATE_GROUPS_UPD'];
    case AccessControlType.InventoryCreateCountControls:
      return ['CT_CREATE_START_COUNT'];
    case AccessControlType.ResetOcnLinePrice:
      return ['OP_ORDER_SCHED_UPD'];
    case AccessControlType.ViewUnbilledReport:
      return ['OM_ORDER_MANAGEMENT_INQ'];
    case AccessControlType.editOcnPrice:
      return ['OP_PRICE_ENTRY_UPD'];
    default:
      return [];
  }
};

interface UseHasAccessControlsResponse {
  hasAccess: boolean;
  hasAccessControl: (accessFlags?: AccessControlType) => boolean;
}

export const useHasAccessControls = (
  propAccessFlags?: AccessControlType
): UseHasAccessControlsResponse => {
  const { accessControl: stateAccessControl } = useSelector(
    (state: RootState) => state.user
  );

  const { data: apiAccessControl } = useGetAccessControl();

  const accessControl = useMemo(() => {
    if (!isNil(apiAccessControl)) {
      return apiAccessControl;
    }
    return stateAccessControl as Dictionary<unknown>;
  }, [apiAccessControl, stateAccessControl]);

  // TODO: extract handling of environment variables to a utility function
  if (useAccessControlsEnv()) {
    return { hasAccess: true, hasAccessControl: () => true };
  }

  const hasAccessControl = (pAccessFlags?: AccessControlType) => {
    const accessFlags = pAccessFlags || propAccessFlags;
    // if there is an access control flag request, it verifies an active value 'Y' for it
    // if not it defaults to true (the user has access by default)
    // this scenario applies to navigation, where some pages don't have access control validation
    if (!isNil(accessFlags)) {
      const accessControlWithOverrides = {
        ...accessControl,
        ...accessControlOverrides,
      };
      return values(
        pick(accessControlWithOverrides, accessControls(accessFlags))
      ).includes('Y');
    }
    return true;
  };

  return { hasAccess: hasAccessControl(), hasAccessControl };
};

const useAccessControls = (accessFlags?: AccessControlType): boolean => {
  const { hasAccess } = useHasAccessControls(accessFlags);
  return hasAccess;
};

export default useAccessControls;
