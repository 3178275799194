import { size, toNumber, toString } from 'lodash';
import i18next from 'i18n/i18n';
import * as yup from 'yup';
import type { SelectModalItem } from 'models/Search';

export interface EditOrderLineForm {
  custComment?: string;
  customerCost?: string;
  originalCustomerCost?: string;
  overridePrice?: string;
  originalPrice?: string;
  unitSellPrice?: string;
  isUpdatePrice?: boolean;
  overrideGP?: string;
  originalGP?: string;
  overrideMode?: boolean;
  userOverride?: string;
  overrideReason?: string;
  overrideReasonText?: string;
  sendConfirmation?: string;
  addToAgreement?: string;
  agreementGP?: string;
  notebookText?: string;
  governmentPricing?: boolean;
  promsdDelivDt?: string;
  csn?: string;
  quantity?: string;
  originalQuantity?: string;
  isZCoded?: boolean;
  uom?: SelectModalItem;
  repairShop?: SelectModalItem;
  isUnfinished?: boolean;
  isRepair?: boolean;
  autoReprice?: boolean;
  triggerRecalcReset?: number;
  isSourcing?: boolean;
  sourcedQty?: number;
  isOapEnabled?: boolean;
  isOverrideCapable?: boolean;
}

const quantityValidationProp = {
  quantity: yup
    .string()
    .required(i18next.t('common:requiredError'))
    .test(
      'moreThan0',
      i18next.t('common:fieldMinNumber', {
        field: i18next.t('common:quantity'),
      }),
      (v) => toNumber(v) > 0
    )
    .when(
      ['isSourcing', 'isUnfinished', 'sourcedQty'],
      ([isSourcing, isUnfinished, sourcedQty], schema) => {
        if (isSourcing && !isUnfinished) {
          return schema.test(
            'is-qty-less-than-sourced',
            i18next.t('productSearch:ocn:totalSourcedQtyMsg', {
              qty: toString(sourcedQty),
            }),
            (val, context) => {
              const values = context.parent as EditOrderLineForm;
              if (val && toNumber(val) < toNumber(values.sourcedQty)) {
                return false;
              }
              return true;
            }
          );
        }
        return schema;
      }
    ),
};

export const QuantitySchema = yup.object().shape(quantityValidationProp);

export const qtyScaleProps = { scale: 1, padFractionalZeros: false };

export const EditOrderLineSchema = yup.object().shape({
  ...quantityValidationProp,
  isZCoded: yup.boolean(),
  overridePrice: yup
    .string()
    .required(i18next.t('productSearch:ocn:priceOverrideRequiredError'))
    .test(
      'moreThan0',
      i18next.t('productSearch:ocn:priceOverrideMoreThanZeroError'),
      (v) => toNumber(v) >= 0
    )
    .when('isUpdatePrice', {
      is: true,
      then: (schema) =>
        schema.required(
          i18next.t('productSearch:ocn:priceOverrideRequiredError')
        ),
    })
    .test(
      'govPricing',
      i18next.t('productSearch:ocn:priceOverrideGovernmentPricingError'),
      (v, context) => {
        const values = context.parent as EditOrderLineForm;
        if (
          values.overridePrice !== values.originalPrice &&
          values.governmentPricing
        ) {
          return (
            (toNumber(values.overridePrice) || 0) <=
            toNumber(values.originalPrice)
          );
        }
        return true;
      }
    ),
  customerCost: yup
    .string()
    .required(i18next.t('productSearch:ocn:customerCostRequiredError'))
    .test(
      'moreThan0',
      i18next.t('productSearch:ocn:customerCostMoreThanZeroError'),
      (v) => toNumber(v) >= 0
    ),
  overrideGP: yup
    .string()
    .required(i18next.t('productSearch:ocn:priceOverrideRequiredError'))
    .test(
      'govPricing',
      i18next.t('productSearch:ocn:priceOverrideGovernmentPricingGpError'),
      (v, context) => {
        const values = context.parent as EditOrderLineForm;
        if (
          values.overridePrice !== values.originalPrice &&
          values.governmentPricing
        ) {
          return (
            (toNumber(values.overridePrice) || 0) <=
            toNumber(values.originalPrice)
          );
        }
        return true;
      }
    ),
  overrideReason: yup
    .string()
    .test(
      'required',
      i18next.t('productSearch:ocn:priceOverrideRequiredError'),
      (v, context) => {
        const values = context.parent as EditOrderLineForm;
        if (
          values.isOapEnabled &&
          values.userOverride !== 'none' &&
          toNumber(values.overridePrice) < toNumber(values.originalPrice)
        ) {
          if (values.overridePrice) {
            return size(v) > 0;
          }
          return false;
        }
        return true;
      }
    ),
  notebookText: yup
    .string()
    .test(
      'required',
      i18next.t('productSearch:ocn:priceOverrideRequiredError'),
      (v, context) => {
        const values = context.parent as EditOrderLineForm;
        if (
          values.isOapEnabled &&
          values.userOverride !== 'none' &&
          toNumber(values.overridePrice) < toNumber(values.originalPrice)
        ) {
          return size(v) > 0;
        }
        return true;
      }
    ),
  isRepair: yup.boolean(),
  repairShop: yup.mixed<SelectModalItem>().when('isRepair', {
    is: true,
    then: (schema) => schema.required(i18next.t('common:requiredError')),
  }),
});
