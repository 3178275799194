import React from 'react';
import classNames from 'classnames';
import { isNil, map, toString } from 'lodash';
import type {
  IonRadioGroupCustomEvent,
  RadioGroupChangeEventDetail,
} from '@ionic/core';
import { IonRadio, IonRadioGroup } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { or } from 'common/utils/logicHelpers';
import type { FirebaseRadioButtonChangeParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './RadioButton.module.scss';

export interface RadioOptionsProps {
  customContent?: React.ReactNode;
  value: string;
  displayValue: string;
  ariaLabel?: string;
  [key: string]: string | undefined | React.ReactNode;
}

interface RadioButtonProps {
  options: RadioOptionsProps[];
  testid: string;
  valueKey?: string;
  displayValueKey?: string;
  onChange: (e: string) => void;
  track?: boolean;
  inline?: boolean;
  isVerticalAlign?: boolean;
  labelTextVariant?: TextVariantType;
  googleAnalyticsId?: string;
}

const RadioButton = ({
  options,
  disabled,
  allowEmptySelection,
  testid,
  valueKey,
  displayValueKey,
  onChange,
  track,
  value,
  labelPlacement = 'end',
  className,
  inline = false,
  isVerticalAlign = false,
  labelTextVariant,
  googleAnalyticsId,
}: RadioButtonProps &
  React.ComponentProps<typeof IonRadio> &
  React.ComponentProps<typeof IonRadioGroup> &
  IonicReactProps): JSX.Element => {
  const onChangeWithTracking = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>
  ) => {
    if (track) {
      const params: FirebaseRadioButtonChangeParams = {
        testid: or(googleAnalyticsId, testid),
        options,
        disabled,
      };
      logFirebaseEvent('radio_button_change', params);
    }

    onChange?.(toString(e.detail.value));
  };

  return (
    <IonRadioGroup
      data-testid={testid}
      allowEmptySelection={allowEmptySelection}
      onIonChange={onChangeWithTracking}
      value={toString(value)}
      className={classNames({ [classes.inline]: inline })}
    >
      {!isNil(options) &&
        map(options, (option, optionIndex) => {
          const radioValue = valueKey ? option[valueKey] : option.value;
          const displayValue = displayValueKey
            ? option[displayValueKey]
            : option.displayValue;
          const { customContent } = option;

          return (
            <React.Fragment key={optionIndex}>
              <IonRadio
                aria-label={
                  toString(option.ariaLabel) || toString(displayValue)
                }
                labelPlacement={labelPlacement}
                value={radioValue}
                disabled={disabled}
                mode="md"
                justify="start"
                className={classNames(
                  {
                    [classes.radio]: !isVerticalAlign,
                    [classes.marginTop]: isVerticalAlign,
                  },
                  className
                )}
              >
                <div className={classes.flex}>
                  {!isNil(customContent) && customContent}
                  {!isNil(displayValue) && (
                    <Text
                      className={classes.text}
                      text={toString(displayValue)}
                      variant={labelTextVariant}
                    />
                  )}
                </div>
              </IonRadio>
              {isVerticalAlign && <br />}
            </React.Fragment>
          );
        })}
    </IonRadioGroup>
  );
};

export default RadioButton;
