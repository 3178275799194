import React from 'react';
import type { Dictionary } from 'lodash';
import { toNumber, toString } from 'lodash';
import CrmTaskForm from 'ActivitiesApp/components/CrmTaskForm/CrmTaskForm';
import {
  SalesOpportunityColorEnum,
  SalesOpportunityEnum,
  SalesOpportunityIconEnum,
} from 'ActivitiesApp/models/SalesOpportunity';
import { isNonBlankEstimatedCloseDate } from 'ActivitiesApp/utils/helpers';
import { choose } from 'common/utils/logicHelpers';
import { formatNumber } from 'common/utils/numberHelper';
import { formatCardDate } from 'utils/date';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getSalesOpportunityData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  if (eventTagName === SalesOpportunityEnum.salesOpportunity) {
    return {
      cardType: SalesOpportunityEnum.salesOpportunity,
      title: t('salesOpportunity'),
      icon: SalesOpportunityIconEnum.salesOpportunity,
      color: SalesOpportunityColorEnum.salesOpportunity,
    };
  }

  return undefined;
};

export const getSalesOpportunityConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { extendedInfo } = activityData;
  const estimatedCloseDate = extendedInfo?.estimatedCloseDate;
  const estimatedValue = extendedInfo?.estimatedValue;
  const currency = extendedInfo?.currency as string;

  const estValue = formatNumber({
    number: toNumber(estimatedValue),
    currencyType: currency,
  });

  if (activityVersion === `${SalesOpportunityEnum.salesOpportunity}-1`) {
    return {
      card: {
        defaultTitle: t('salesOpportunity'),
        highlight: [{ query: t('salesOpportunity') }],
        body: {
          description: '',
          lines: [
            ...defaultFirstLine,
            { description: toString(extendedInfo?.subject) },
            {
              description: choose(
                isNonBlankEstimatedCloseDate(toString(estimatedCloseDate)),
                t('estimatedCloseDate', {
                  estimatedCloseDate: formatCardDate(
                    toString(estimatedCloseDate),
                    false,
                    false
                  ),
                }),
                ''
              ) as string,
              highlight: t('estimatedCloseDate'),
              followUpDescription: estimatedValue
                ? t('activities:crmActivityEst', { estValue })
                : '',
              followUpHighlight: t('activities:crmActivityEst'),
            },
          ],
        },
      },
      modal: {
        title: t('salesOpportunity'),
        customContent: (props: Dictionary<unknown>) => (
          <CrmTaskForm
            activity={activityData}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        ),
      },
    };
  }
  return undefined;
};
