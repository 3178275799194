import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  capitalize,
  filter,
  isEmpty,
  map,
  some,
  toNumber,
  values,
  toString,
} from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  NavContext,
  useIonViewWillEnter,
} from '@ionic/react';
import type { Dictionary } from '@reduxjs/toolkit';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { and, choose, or } from 'common/utils/logicHelpers';
import { homeURL, searchURL } from 'navigation';
import useUpdateHeader from 'ProductSearchApp/api/checkout/useUpdateHeader';
import useDeleteOrderItem from 'ProductSearchApp/api/orders/useDeleteOrderItem';
import useAddToCart from 'ProductSearchApp/api/useAddToCart';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useUpdateToCart from 'ProductSearchApp/api/useUpdateToCart';
import CartLineItem from 'ProductSearchApp/components/CartLineItem/CartLineItem';
import SelectCustomerBar from 'ProductSearchApp/components/SelectCustomerBar/SelectCustomerBar';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import { OrderListTabEnum } from 'ProductSearchApp/pages/CustomerOrders/CustomerOrders';
import { canEditOCN } from 'ProductSearchApp/util/ocnHelpers';
import {
  isCustomerLess,
  useHandleCustomerUpdate,
} from 'ProductSearchApp/util/productSearchUtil';
import { useToasts } from 'providers/ToastProvider';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useFirstRef from 'hooks/useFirstRef';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { SelectModalItem } from 'models/Search';
import type { RootState } from 'store/reducers';
import type { CurrentCartCustomer } from 'store/user';
import { clearCurrentCartCustomer } from 'store/user';
import { CUSTOMERLESS } from 'utils/constants';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import { handleSearchNavigation } from 'utils/search';
import {
  goToAddZCodedItem,
  goToOrderCart,
  goToProductSearch,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './OrderCart.module.scss';

export interface UpdatedLineItemProp {
  orderLineNo?: string;
  quantity?: string;
  originalQuantity?: string;
  expectedDate?: string;
  isSourcing?: boolean;
  isUnfinished?: boolean;
  sourcedQty?: number;
  cancelwo?: string;
  notebookText?: string;
}

export interface QuantitySetFieldProps {
  quantity: string;
  lineItemIndex: number;
  orderLineNo: string;
}

interface HeaderActionItemProp {
  icon?: IconProp;
  href?: string;
  text: string;
  testid: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const OrderCart = (): JSX.Element => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const history = useHistory();
  const { addToast } = useToasts();
  const { resetTab } = useContext(NavContext);

  const {
    miLoc,
    id: shipToCustNo,
    ocn: orderCtlNo,
  } = useParams<OrderURLParams>();
  const { currentCartCustomer = {}, showCostDetails } = useSelector(
    (state: RootState) => state.user
  );

  const cartMiLoc = toString(currentCartCustomer?.miLoc);
  const cartOcn = toString(currentCartCustomer?.orderCtlNo);
  const cartCustomerNo = toString(currentCartCustomer?.shipToCustNo);

  const isCustomerLessSearch =
    cartCustomerNo === shipToCustNo && isCustomerLess(shipToCustNo);

  const [isOpenClearCart, setIsOpenClearCart] = useState(false);
  const [isOpenAbandonModal, setIsOpenAbandonModal] = useState(false);
  const [isOpenSelCustQuoteModal, setIsOpenSelCustQuoteModal] = useState(false);
  const [isOpenSelCustOrderModal, setIsOpenSelCustOrderModal] = useState(false);
  const [triggerCustomerSelect, setTriggerCustomerSelect] = useState(0);

  const [removeOrder, setRemoveOrder] = useState<CurrentCartCustomer>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [datePickerDate, setDatePickerDate] = useState<Date>(new Date());
  const [selectedOrderLineNo, setSelectedOrderLineNo] = useState<string>();
  const [triggerSubmit, setTriggerSubmit] = useState<number>();
  const [itemDate, setItemDate] = useState<Date>();
  const [focusedOrderLineNo, setFocusOrderLineNo] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const [updatingCustomer, setUpdatingCustomer] = useState(false);
  const [formikError, setFormikError] = useState<Dictionary<boolean>>({});

  const updateFormikError = (orderLineNo: string, formikErr: boolean): void => {
    setFormikError((prev) => ({ ...prev, [orderLineNo]: formikErr }));
  };

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerError,
  } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id: shipToCustNo,
    enabled: !isCustomerLess(shipToCustNo) && !isEmpty(currentCartCustomer),
  });

  const {
    order,
    isLoading: orderIsLoading,
    error: orderError,
  } = useGetOrder({ miLoc, orderCtlNo, refetchOnEnter: true });

  const { data: attachmentsData, isLoading: attachmentsLoading } =
    useFindAttachments({
      domain: 'order',
      miLoc,
      ctlNo: orderCtlNo,
      lineNo: '0',
      enabled: !isEmpty(shipToCustNo),
    });

  const { onUpdateHeader, status: updateHeaderStatus } = useUpdateHeader({
    miLoc,
    orderCtlNo,
    shipToCustNo: '',
    billToCustNo: '',
  });

  const { status: addCartStatus, onAddToCart } = useAddToCart();

  const isLoading = or(
    orderIsLoading,
    customerIsLoading,
    addCartStatus === 'loading'
  );
  const error = or(orderError, customerError);
  const namespace = 'ProductSearchApp-Ocn';
  const zCodedNameSpace = 'ProductSearchApp-Ocn:zCodedItem';

  const { t } = useTranslation(namespace);

  const { status: updateStatus, onUpdateToCart } = useUpdateToCart({});
  const firstRef = useFirstRef();

  useEffect(() => {
    if (and(isEmpty(currentCartCustomer), !!removeOrder?.miLoc)) {
      if (isCustomerLess(shipToCustNo)) {
        history.replace(searchURL());
      } else {
        history.replace(
          concatRoutes(
            searchURL(),
            handleSearchNavigation({
              type: 'customer',
              miLoc,
              customerId: shipToCustNo,
            })
          )
        );
      }

      setRemoveOrder(undefined);
    }
  }, [history, currentCartCustomer, miLoc, shipToCustNo, removeOrder?.miLoc]);

  const headerActionsList = useMemo(() => {
    if (isEmpty(orderCtlNo)) {
      return [];
    }
    const isShipToCustEmpty = isEmpty(shipToCustNo);

    const headerActionsArray: HeaderActionItemProp[] = [
      {
        text: t(`${namespace}:addCatalogItem`),
        href: choose(
          isCustomerLessSearch,
          homeURL(),
          goToProductSearch(miLoc, shipToCustNo, true)
        ) as string,
        testid: 'add-item-button',
      },
    ];
    if (!isCustomerLessSearch) {
      headerActionsArray.push({
        text: t(`${zCodedNameSpace}:addZCodedItem`),
        href: goToAddZCodedItem({
          miLoc,
          shipToCustNo,
          orderCtlNo,
          reset: true,
        }),
        testid: 'add-zcoded-button',
      });
    }
    headerActionsArray.push({
      ...toggleCostDetailsOption(false),
      href: undefined,
    });
    if (isCustomerLessSearch) {
      headerActionsArray.push({
        text: t(`${namespace}:selCust`),
        onClick: () => setTriggerCustomerSelect(Date.now()),
        testid: 'header-order-cart-cust',
      });
      headerActionsArray.push({
        icon: ['far', 'trash-can'],
        text: t(`${namespace}:abandonOcn`),
        onClick: () => setIsOpenAbandonModal(true),
        testid: 'abandon-ocn-button',
        className: classes.clearText,
      });
    } else {
      headerActionsArray.push({
        text: t(`${namespace}:clearOcn`),
        onClick: () => setIsOpenClearCart(true),
        disabled: isShipToCustEmpty,
        testid: 'clear-item-button',
      });
    }

    return headerActionsArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCostDetails]);

  const headerActions = {
    title: t(`${namespace}:manageOcn`),
    initialBreakpoint: 0.5,
    disabled,
    options: headerActionsList,
  };
  const disableButtons = or(
    disabled,
    isLoading,
    !canEditOCN(order),
    !!error,
    some(values(formikError), (errorItem) => errorItem === true)
  );
  const disableQuoteButton = and(order?.orderTypeCd === 'O', order?.isSourced);
  let ocnType = OrderListTabEnum.unfinished;
  if (order?.orderTypeCd === 'O') {
    ocnType = choose(
      order.processStatus === 'CL',
      OrderListTabEnum.closed,
      OrderListTabEnum.orders
    ) as OrderListTabEnum;
  } else if (order?.orderTypeCd === 'Q') {
    ocnType = OrderListTabEnum.quotes;
  }

  const {
    status: deleteStatus,
    onDeleteOrderItem,
    onOptimisticDeleteItem,
    rollbackDeleteItem,
  } = useDeleteOrderItem();

  const deleteIsLoading = deleteStatus === 'loading';

  useEffect(() => {
    if (!deleteIsLoading) {
      setDisabled?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteIsLoading]);

  const onDeleteItem = async (lineItem: CartOrderItem, lostSale: string) => {
    setDisabled?.(true);
    await onOptimisticDeleteItem(lineItem.orderLineNo);
    addToast({
      variant: 'mipro-toast',
      text: t(`${namespace}:deleteItemToast`, {
        orderCtlNo: removeLeadingZeros(order?.orderCtlNo),
        orderLineNo: lineItem.orderLineNo,
      }),
      button: {
        text: t('common:undo'),
        handler: async () => {
          setDisabled?.(false);
          await rollbackDeleteItem(lineItem.orderLineNo);
        },
      },
      onClose: () => {
        setRemoveOrder(currentCartCustomer);
        onDeleteOrderItem({
          deleteOption: lineItem.deleteOption,
          orderLineNo: lineItem.orderLineNo,
          lostSale,
        });
      },
      testid: 'delete-item-success-toast',
    });
  };

  let title = t(`${namespace}:ocnNo`, {
    orderCtlNo: removeLeadingZeros(orderCtlNo),
  });
  if (order?.orderTypeCd === 'O') {
    title = t(`${namespace}:ocnNoOrder`, {
      orderCtlNo: removeLeadingZeros(orderCtlNo),
    });
  }
  if (order?.orderTypeCd === 'Q') {
    title = t(`${namespace}:ocnNoQuote`, {
      orderCtlNo: removeLeadingZeros(orderCtlNo),
    });
  }

  const { doOcnCustomerUpdate } = useHandleCustomerUpdate();

  const setCustomerInfo = async (o: SelectModalItem) => {
    if (o.key) {
      setUpdatingCustomer?.(true);
      setDisabled(true);
      const selectedMiLoc = o.key.substring(0, 4);
      const selectedCustNo = o.key.substring(4);
      await doOcnCustomerUpdate({
        orderCtlNo,
        cartMiLoc: miLoc,
        miLoc: selectedMiLoc,
        shipToCustNo: selectedCustNo,
        customerName: o?.title,
        onUpdateHeader,
        onAddToCart,
      });
    }
  };

  useEffect(() => {
    if (or(updateHeaderStatus === 'success', addCartStatus === 'success')) {
      setUpdatingCustomer?.(false);
      setDisabled(false);
      history.replace(goToOrderCart(cartMiLoc, cartCustomerNo, cartOcn));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHeaderStatus, addCartStatus, cartOcn]);

  useIonViewWillEnter(() => {
    if (
      and(
        shipToCustNo === CUSTOMERLESS,
        !isEmpty(currentCartCustomer),
        cartCustomerNo !== CUSTOMERLESS
      )
    ) {
      history.replace(goToOrderCart(cartMiLoc, cartCustomerNo, cartOcn));
    }
  }, [shipToCustNo, cartCustomerNo, cartMiLoc, cartOcn]);

  const errorOrCardContent = error ? (
    <WarningMessage
      className={classes.warningMessage}
      title={t(`${namespace}:errorOcn`, {
        ocn: removeLeadingZeros(orderCtlNo),
      })}
      body={getErrorMessage(error)}
      testid="error-holder"
    />
  ) : (
    <>
      <SelectCustomerBar
        testid="order-cart"
        isCustomerLessSearch={isCustomerLessSearch && !updatingCustomer}
        setCustomerInfo={setCustomerInfo}
        triggerCustomerSelect={triggerCustomerSelect}
      />
      <Header
        className={classes.contentHeader}
        collapse="condense"
        customTitle={
          <>
            <IonRow className={classes.headerRow}>
              <Text
                text={title}
                variant="mipro-h1-headline"
                testid="shopping-cart-cust-title"
              />
              <Button
                className={classes.addItemButton}
                key="add-item"
                text={t(`${namespace}:addItem`)}
                variant="link"
                disabled={disabled}
                textVariant="mipro-body-copy-bold"
                href={
                  choose(
                    isCustomerLessSearch,
                    homeURL(),
                    goToProductSearch(miLoc, shipToCustNo, true)
                  ) as string
                }
                testid="add-item"
              />
            </IonRow>
            <CustomerName
              customerData={customerData}
              customButton={{ disabled }}
            />
          </>
        }
        testid="quote-cart-header"
      />
      <Spinner
        className={classes.spinner}
        testid="cart-spinner"
        text={updatingCustomer ? t('updatingCustomer') : t('common:updating')}
        showSpinner={disabled}
      />
      <IonGrid className={classes.summaryGrid}>
        <IonRow className={classes.summaryTitle}>
          <Text
            text={t(`${namespace}:orderSummary`)}
            variant="title-screen-section"
            testid="shopping-cart-cust-ocn"
          />
        </IonRow>
        <IonRow className={classes.totalRow}>
          <IonCol className={classes.estimatedCol}>
            <Text
              text={t(`${namespace}:estimatedTotal`)}
              variant="list-item-subtitle"
            />
          </IonCol>
          <IonRow>
            <IonCol className={classes.amtCol}>
              <IonRow>
                <CurrencyFormat
                  className={classes.totalPrice}
                  value={toNumber(order?.totalOrderAmt)}
                  variant="mipro-h1-headline"
                  scale={2}
                  currencyType="USD"
                  testid="total-order-amt"
                />
              </IonRow>
              <IonRow>
                <Text
                  className={classes.amtOverline}
                  text={t(`${namespace}:estimatedHelpText`)}
                  variant="list-item-secondaryText"
                />
              </IonRow>
            </IonCol>
          </IonRow>
        </IonRow>
      </IonGrid>
      <div>
        {map(order?.items, (item) => (
          <CartLineItem
            key={item.orderLineNo}
            isCustomerLessCart={isCustomerLessSearch}
            testid={item.orderLineNo}
            order={order}
            lineItem={item}
            attachments={filter(
              attachmentsData,
              (i) => i.LINE_NO === item.orderLineNo
            )}
            disabled={disabled}
            isUpdatingCustomer={updatingCustomer}
            baseUrl={url}
            focusedOrderLineNo={focusedOrderLineNo}
            setFocusOrderLineNo={setFocusOrderLineNo}
            selectedOrderLineNo={selectedOrderLineNo}
            setSelectedOrderLineNo={setSelectedOrderLineNo}
            triggerSubmit={triggerSubmit}
            itemDate={choose(
              selectedOrderLineNo === item.orderLineNo,
              formatDate(itemDate, DateFormatEnum.standardDate),
              item.promsdDelivDt
            )}
            setDatePickerDate={setDatePickerDate}
            setIsCalendarOpen={setIsCalendarOpen}
            onDeleteItem={onDeleteItem}
            updating={order?.linesUpdating?.includes(item.orderLineNo)}
            onUpdateToCart={onUpdateToCart}
            updateStatus={updateStatus}
            updateFormikError={updateFormikError}
          />
        ))}
      </div>
    </>
  );

  const resultCardContent = isEmpty(currentCartCustomer) ? (
    <WarningMessage
      className={classes.warningMessage}
      title={t(`${namespace}:emptyOcnTitle`)}
      icon={['fas', 'cart-shopping']}
      body={t(`${namespace}:emptyOcnMsg`)}
      testid="empty-customer-no"
    >
      <Button
        className={classes.searchCustBtn}
        variant="action"
        text={t('common:searchCustomer')}
        testid="search-customer"
        href={searchURL()}
      />
    </WarningMessage>
  ) : (
    errorOrCardContent
  );

  return (
    <IonPage className={classes.cartPage} data-testid="order-cart-page">
      <Header
        customTitle={choose(
          !isCustomerLessSearch,
          <CustomerName customerData={customerData} />
        )}
        title={choose(isCustomerLessSearch, title)}
        hideMenuButton
        headerActions={headerActions}
        testid="order-cart-header"
      />
      <IonContent>
        {/* eslint-disable-next-line no-nested-ternary */}
        {or(and(isLoading, firstRef), attachmentsLoading, deleteIsLoading) ? (
          <Loader
            className={classes.loader}
            isOpen={or(isLoading, attachmentsLoading, deleteIsLoading)}
            testid="cart-loader"
            text={choose(
              isLoading,
              t(`${namespace}:orderLoading`),
              choose(attachmentsLoading, t(`${namespace}:attachmentLoading`))
            )}
          />
        ) : (
          resultCardContent
        )}
        <SheetModal
          title={t(`${namespace}:clearOcnTitle`)}
          isOpen={isOpenClearCart}
          withRightCloseButton
          initialBreakpoint={0.5}
          testid="clear--cart--modal"
          className={classes.clearOcn}
          setIsOpen={setIsOpenClearCart}
        >
          <div className={classes.text}>
            <Text
              text={t(`${namespace}:clearOcnText`, {
                ocn: removeLeadingZeros(orderCtlNo),
                ocnType: capitalize(ocnType),
              })}
              variant="mipro-body-copy"
            />
          </div>
          <div className={classes.btnWrapper}>
            <Button
              testid="clear--cart--button"
              variant="action"
              text={t(`${namespace}:clearOcn`)}
              className={classes.btn}
              onClick={() => {
                const navigationUrl = handleSearchNavigation({
                  type: 'customer',
                  miLoc,
                  customerId: shipToCustNo,
                });
                dispatch(clearCurrentCartCustomer());
                setIsOpenClearCart(false);
                history.replace(concatRoutes(searchURL(), navigationUrl));
              }}
            />
          </div>
          <div className={classes.btnWrapper}>
            <Button
              testid="clear--cart--cancel-button"
              variant="secondary"
              text={t('common:cancel')}
              onClick={() => setIsOpenClearCart(false)}
              className={classes.btn}
            />
          </div>
        </SheetModal>
        <DatePickerModal
          isOpen={isCalendarOpen}
          setIsOpen={setIsCalendarOpen}
          title={t('productSearch:checkout:expectedDt')}
          onDone={(date) => {
            setItemDate(date);
            setTriggerSubmit(Date.now());
          }}
          minDate={new Date()}
          date={datePickerDate}
          testid="expected-date-calendar"
        />
      </IonContent>
      {!isEmpty(currentCartCustomer) && (
        <Footer
          buttons={[
            {
              text: t(`${namespace}:order`),
              testid: 'order-btn',
              variant: 'secondary',
              disabled: disableButtons,
              href: choose(
                !isCustomerLessSearch,
                goToReviewCart({
                  miLoc,
                  shipToCustNo,
                  orderCtlNo,
                  ocnType: 'order',
                  ocnMode: 'checkout',
                  reset: true,
                })
              ),
              onClick: choose(isCustomerLessSearch, () =>
                setIsOpenSelCustOrderModal(true)
              ),
            },
            {
              text: t(`${namespace}:quote`),
              testid: 'quote-btn',
              variant: 'mipro-action',
              disabled: or(disableButtons, disableQuoteButton),
              href: choose(
                !isCustomerLessSearch,
                goToReviewCart({
                  miLoc,
                  shipToCustNo,
                  orderCtlNo,
                  ocnType: 'quote',
                  ocnMode: 'checkout',
                  reset: true,
                })
              ),
              onClick: choose(isCustomerLessSearch, () =>
                setIsOpenSelCustQuoteModal(true)
              ),
            },
          ]}
        />
      )}
      <DiscardModal
        title={t('selCust')}
        discardMsg={choose(
          isOpenSelCustQuoteModal,
          t('selCustMsgQuote'),
          t('selCustMsgOrder')
        )}
        initialBreakpoint={0.35}
        withRightCloseButton
        setIsOpen={choose(
          isOpenSelCustQuoteModal,
          setIsOpenSelCustQuoteModal,
          setIsOpenSelCustOrderModal
        )}
        isOpen={or(isOpenSelCustQuoteModal, isOpenSelCustOrderModal)}
        testid="sel-cust-modal"
        discardButtonTitle={t('cancel')}
        goBackButtonTitle={t('selCust')}
        onGoBackClick={() => {
          setTriggerCustomerSelect(Date.now());
        }}
      />
      <DiscardModal
        title={t('abandonOcn')}
        discardMsg={t('abandonOcnMsg')}
        initialBreakpoint={0.35}
        withRightCloseButton
        setIsOpen={setIsOpenAbandonModal}
        isOpen={isOpenAbandonModal}
        testid="abandon-ocn-modal"
        discardButtonTitle={t('cancel')}
        goBackButtonTitle={t('abandonOcn')}
        onGoBackClick={() => {
          dispatch(clearCurrentCartCustomer());
          setIsOpenAbandonModal(false);
          resetTab('search', searchURL());
        }}
      />
    </IonPage>
  );
};

export default OrderCart;
