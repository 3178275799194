import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';
import type { Dictionary } from 'lodash';
import {
  includes,
  padEnd,
  toString,
  filter,
  some,
  trim,
  uniq,
  values,
  sumBy,
  toNumber,
  toUpper,
  chain,
} from 'lodash';
import { emptyString } from 'common/utils/valueFormatter';
import i18next from 'i18next';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type {
  CartOrderItem,
  ItemSourcing,
  Order,
} from 'ProductSearchApp/models/Order';
import type { Product } from 'ProductSearchApp/models/Products';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import { productImageUrl } from './productSearchUtil';

export const canEditOCN = (order?: Order) =>
  includes(['OP', 'UM'], order?.processStatus) &&
  includes(['O', 'Q', 'U'], order?.orderTypeCd);

export const getProductFromOcnLine = (
  lineItem?: CartOrderItem,
  removePricingData = false
) =>
  ({
    ...lineItem,
    imageURL: lineItem?.itemImageName
      ? `${productImageUrl}${lineItem?.itemImageName}`
      : '',
    manufacturerName: lineItem?.mfrName,
    mfgPartNumber: lineItem?.mfgPartNo,
    itemNumber: lineItem?.itemNo,
    mino: `${toString(lineItem?.mfrCtlNo)}${toString(lineItem?.groupSerial)}`,
    customerStockNumber: lineItem?.custStockNo,
    gpPct: lineItem?.grossProfitPct,
    itemUOMDesc: lineItem?.salesUOM,
    hasOpportunities: lineItem?.hasOpportunities ? 'Y' : '',
    ...(removePricingData
      ? { gpPct: undefined, unitSellPrice: undefined }
      : {}),
  } as unknown as Product);

export const zCodedItemNo = '99999999';
export const isZcodedItem = (itemNo?: string) => itemNo === zCodedItemNo;

export const mergeEmailRecipients = (
  existingEmails?: string,
  append?: string,
  joinBy = '; '
) => {
  const trimmed = filter(
    `${toUpper(existingEmails)};${toUpper(append)}`.split(/[\s;]+/)
  ).map((recipient) => trim(recipient));
  return uniq(trimmed).join(joinBy);
};

export const MAX_LINE_ITEM_QUANTITY = 9999999.9;
export const MAX_LINE_ITEM_TOTAL = 999999999.99;
export const LINE_ITEM_QUANTITY_REGEXP = /^[0-9]{0,7}((\.)[0-9]{0,1})?$/;

export const isUnfinishedOrder = (orderTypeCd?: string) => orderTypeCd === 'U';

export const getItemShopSourcing = (lineItem?: CartOrderItem) =>
  filter(lineItem?.itemSourcing, ({ shopFlag }) => shopFlag === 'Y');

export const getDisableByShopSourcing = (
  order?: Order,
  lineItem?: CartOrderItem
) =>
  !isUnfinishedOrder(order?.orderTypeCd) &&
  some(lineItem?.itemSourcing, ({ shopFlag }) => shopFlag === 'Y');

export const padGroupSerial = (group = '') => padEnd(group, 6, '0');

export const buildItemMino = (mfrCtlNo = '', group = '') =>
  mfrCtlNo && group ? `${mfrCtlNo}${padGroupSerial(`Z${group}`)}` : emptyString;

export const useRepriceToast = (price = '', gp = '') => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const prevPrice = usePrevious(price);
  const prevGP = usePrevious(gp);

  return {
    showRepriceToast: (onAddToast?: () => void) => {
      if (prevPrice !== price || prevGP !== gp) {
        onAddToast?.();
        addToast({
          type: ToastType.info,
          variant: 'mipro-toast',
          header: t('productSearch:ocn:repricedToastTitle', {
            from:
              prevPrice !== price
                ? `$${toString(prevPrice)}`
                : `${toString(prevGP)}%`,
            to: prevPrice !== price ? `$${price}` : `${gp}%`,
          }),
          text: t('productSearch:ocn:repricedToastMessage'),
          testid: 'reprice-toast',
        });
      }
    },
  };
};

export const hasPriceOverride = (item?: CartOrderItem) => {
  const priceOverride = toNumber(item?.priceOverridePrice);
  const isZeroPriceOverride =
    priceOverride === 0 &&
    !!item?.priceOverrideReason &&
    !!item?.priceOverrideRequestUserid;
  return priceOverride > 0 || isZeroPriceOverride;
};

export const sumOfSourcedQuantity = (lineItem?: CartOrderItem) =>
  sumBy(values(lineItem?.itemSourcing), (source) =>
    toNumber(source.qtyRequested)
  );

export const cancelWoStatus = (itemSourcing?: Dictionary<ItemSourcing>) =>
  some(
    itemSourcing,
    ({ workOrderCancelRequestedFlag }) => workOrderCancelRequestedFlag === 'Y'
  );

export const isCancelledItem = (lineStatus?: string) =>
  includes(lineStatus, 'Cancelled');

export const canCancelWo = (
  order?: Order,
  itemSourcing?: Dictionary<ItemSourcing>
) =>
  !isUnfinishedOrder(order?.orderTypeCd) &&
  some(
    itemSourcing,
    ({ shopFlag, requestType, lineStatus }) =>
      shopFlag === 'Y' &&
      (requestType === 'WO' || requestType === 'WQ') &&
      lineStatus !== 'PE'
  );

export const isClosedOrder = (order?: Order) =>
  order?.orderTypeCd === 'O' && order?.processStatus === 'CL';

export const getOcnType = (order?: Order): OcnTypeEnum => {
  let ocnType = OcnTypeEnum.unfinished;
  if (order?.orderTypeCd === 'Q') {
    ocnType = OcnTypeEnum.quote;
  } else if (order?.orderTypeCd === 'O') {
    ocnType = isClosedOrder(order) ? OcnTypeEnum.closed : OcnTypeEnum.order;
  }
  return ocnType;
};

export const getOrderLineItemPayload = (lineItem?: CartOrderItem) => {
  if (lineItem) {
    const {
      itemNo,
      groupSerial,
      custStockNo,
      mfgPartNo,
      unitSellPrice,
      unitCost,
      orderLineNo,
      salesUOM,
      mfrCtlNo,
      opQtyOrdered,
      promsdDelivDt,
      affectUsageFlag,
      custComment,
    } = lineItem;
    return {
      itemNo,
      groupSerial,
      custStockNo,
      mfgPartNo,
      unitSellPrice,
      unitCost,
      orderLineNo,
      salesUOM,
      mfrCtlNo,
      mino: `${mfrCtlNo}${groupSerial}`,
      quantity: opQtyOrdered,
      promsdDelivDt,
      affectUsageFlag,
      custComment,
      dirty: 'Y',
      itemInfoChange: 'Y',
    };
  }
  return undefined;
};

export const getPoText = (custPONo?: string, custReleaseNo?: string) => {
  let value = custPONo;
  value ||= custReleaseNo;
  return i18next.t('search:poText', {
    custPONo,
    custReleaseNo,
    valueNo: value,
    count: !custPONo || !custReleaseNo ? 0 : 1,
  });
};

export const isGovAcc = (order?: Order) => !!order?.governmentAccountFlag;

export const hasGovMfrItems = (order?: Order) =>
  chain(values(order?.items))
    .filter({ governmentAccountMfrFlag: true })
    .map(({ mfrName }) => mfrName)
    .value();

export const convertPrice = (
  price: number,
  commercialToFunctionalExchange: number
) => {
  return price * commercialToFunctionalExchange;
};

export const calculateGp = (price: number, cost: number) => {
  return ((price - toNumber(cost)) / price) * 100;
};

export const calculateNewprice = (
  cost: number,
  gp: number,
  commercialToFunctionalExchange: number
) => {
  return cost / (1 - gp / 100) / commercialToFunctionalExchange;
};
