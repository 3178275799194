import type { Dictionary } from 'lodash';
import { flatten, keyBy, map } from 'lodash';
import useAPIUrl from 'api';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { employeeStatus } from 'SearchApp/utils/helpers';
import type { Employee } from 'models/Employee';

const findActiveEmployeedQuerykey = 'active-employees';

interface UseFindActiveEmployeesProps {
  enabled?: boolean;
}

interface UseFindActiveEmployeesResponse extends QueryFlags {
  activeEmployeeList?: Dictionary<Employee>;
}
export const formatActiveEmployeeList = (employeeList: Employee[]) => {
  const activeEmployeeList: Dictionary<Employee> = keyBy(
    map(employeeList, (employee) => ({
      searchTokens: flatten([
        employee.firstName?.split(' '),
        employee.lastName?.split(' '),
        employee.miLoc,
      ]),
      status: employeeStatus(employee.lastLoggedIn),
      ...employee,
    })),
    'id'
  );
  return activeEmployeeList;
};

const useFindActiveEmployees = ({
  enabled,
}: UseFindActiveEmployeesProps): UseFindActiveEmployeesResponse => {
  const { axios } = useAxios();
  const { findActiveEmployeesAPI } = useAPIUrl();

  const response = useQuerySetup<Dictionary<Employee>>({
    queryKey: findActiveEmployeedQuerykey,
    isInfiniteQuery: false,
    enabled,
    options: {
      refetchIntervalInBackground: true,
      refetchInterval: 600000,
    },
    onAPIRequest: async ({ signal }) => {
      const { data } = await axios.get<Employee[]>(findActiveEmployeesAPI(), {
        signal,
      });

      return formatActiveEmployeeList(data);
    },
  });

  return {
    activeEmployeeList: response.data as Dictionary<Employee>,
    isLoading: response.isLoading,
  };
};

export default useFindActiveEmployees;
