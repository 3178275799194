import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { or } from 'common/utils/logicHelpers';
import { LoggedInStatusEnum } from 'SearchApp/utils/helpers';
import classes from './EmployeeStatus.module.scss';

interface EmployeeStausProps extends BaseComponentProps {
  status: LoggedInStatusEnum;
}

const EmployeeStatus = ({ status, testid }: EmployeeStausProps) => {
  return (
    <FontAwesomeIcon
      icon={['fas', 'circle']}
      className={classNames(classes.icon, {
        [classes.active]: status === LoggedInStatusEnum.active,
        [classes.inActive]: or(
          status === LoggedInStatusEnum.inactive,
          isEmpty(status)
        ),
        [classes.away]: status === LoggedInStatusEnum.away,
      })}
      data-testid={testid}
    />
  );
};

export default EmployeeStatus;
