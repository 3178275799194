import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find, first, set, size, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import type { Order } from 'ProductSearchApp/models/Order';
import type { UpdateToCart } from 'ProductSearchApp/models/Products';
import { isUnfinishedOrder } from 'ProductSearchApp/util/ocnHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import { onMutateUpdate, useKeyUserId } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { findOrdersQueryKey } from './orders/useFindOrders';

interface UseUpdateToCartResponse {
  status: MutationStatus;
  data?: Order;
  onUpdateToCart: (body: UpdateToCart) => void;
  onOptismitcUpdateItem?: (
    orderLineNo: string
  ) => Promise<UpdateMutationContext<Order>[]>;
  onRollbackItem?: (
    orderLineNo: string
  ) => Promise<UpdateMutationContext<Order>[]>;
  onOrderSuccessUpdate?: (data: Order) => void;
}

interface UpdateToCartProps {
  miLoc?: string;
  orderCtlNo?: string;
  shipToCustNo?: string;
  billToCustNo?: string;
}

const useUpdateToCart = (props: UpdateToCartProps): UseUpdateToCartResponse => {
  const { axios } = useAxios();
  const { addToCartAPI, createPriceOverrideAPI, addItemSourcingAPI } =
    useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const ocnProps = props.orderCtlNo ? props : currentCartCustomer;
  const {
    miLoc = '',
    orderCtlNo = '',
    shipToCustNo = '',
    billToCustNo = '',
  } = ocnProps || {};

  const onOptismitcUpdateItem = async (orderLineNo: string) => {
    const cachedOrder = queryClient.getQueryData<Order>(
      createQueryKey(getOrderQueryKey, { orderCtlNo, miLoc })
    );
    const linesUpdating = cachedOrder?.linesUpdating || [];
    linesUpdating.push(orderLineNo);
    return onMutateUpdate<Order>({
      queryClient,
      queryKey: getOrderQueryKey,
      updatedItems: [{ linesUpdating } as Order],
      dataPath: 'orders',
      isSingleQuery: true,
      findPredicates: [{ miLoc, orderCtlNo }],
    });
  };

  const onRollbackItem = async (orderLineNo: string) => {
    const cachedOrder = queryClient.getQueryData<Order>(
      createQueryKey(getOrderQueryKey, { miLoc, orderCtlNo })
    );
    const tmpLinesUpdating = cachedOrder?.linesUpdating || [];
    const linesUpdating = tmpLinesUpdating.filter((obj) => obj !== orderLineNo);
    return onMutateUpdate<Order>({
      queryClient,
      queryKey: getOrderQueryKey,
      updatedItems: [{ linesUpdating } as Order],
      dataPath: 'orders',
      isSingleQuery: true,
      findPredicates: [{ miLoc, orderCtlNo }],
    });
  };

  const onOrderSuccessUpdate = (data?: Order) => {
    // TODO create utils to update query data
    queryClient.setQueryData<Order>(
      createQueryKey(getOrderQueryKey, { miLoc, orderCtlNo }),
      data
    );
    void onMutateUpdate<Order>({
      queryClient,
      queryKey: findOrdersQueryKey,
      updatedItems: [{ ...data }],
      dataPath: 'orders',
      markAsUpdated: false,
      findPredicates: [{ orderCtlNo }],
      isInfiniteQuery: true,
    });
  };

  const doUpdateToCart = async (body: UpdateToCart) => {
    const {
      overrideMode,
      overrideCost,
      sourceList,
      isOverrideCapable,
      ...restBody
    } = body;
    const updatePayload = {
      customerInfo: { miLoc, customerNo: shipToCustNo, billToNo: billToCustNo },
      orderInfo: { miLoc, orderCtlNo, shipToCustNo },
    };
    const updateBody = { ...restBody, ...updatePayload };

    if (overrideMode) {
      if (overrideCost) {
        await axios.put<Order>(addToCartAPI(), updateBody);
      }

      if (!isOverrideCapable) {
        set(updateBody, 'itemList.0.unitSellPrice', overrideMode.overridePrice);
      } else {
        const { data: overrideData } = await axios.post<Order>(
          createPriceOverrideAPI(),
          { miLoc, orderCtlNo, ...overrideMode }
        );
        const lineItem = find(overrideData.items, {
          orderLineNo: overrideMode.orderLineNo,
        });
        set(updateBody, 'itemList.0.unitSellPrice', lineItem?.unitSellPrice);
      }
    }

    let { data } = await axios.put<Order>(addToCartAPI(), updateBody);

    if (isUnfinishedOrder(data.orderTypeCd) && size(sourceList) > 0) {
      ({ data } = await axios.post<Order>(addItemSourcingAPI(), {
        ...updatePayload,
        item: { orderLineNo: first(restBody.itemList)?.orderLineNo },
        sourceList,
      }));
    }
    return data;
  };

  const response = useMutation(doUpdateToCart, {
    onMutate: async (vars) => {
      await onOptismitcUpdateItem(toString(vars.itemList[0].orderLineNo));
    },
    onSuccess: (data) => {
      // TODO create utils to update query data
      onOrderSuccessUpdate(data);
    },
    onError: async (error, vars) => {
      addToast({
        type: ToastType.error,
        text: t('productSearch:ocn:addToOcnFailedToast'),
        testid: 'add-to-cart-error-toast',
      });
      await onRollbackItem(toString(vars.itemList[0].orderLineNo));
    },
  });

  const { status, mutate, data } = response;

  return {
    status,
    data,
    onUpdateToCart: (body: UpdateToCart) => mutate(body),
    onOptismitcUpdateItem,
    onRollbackItem,
    onOrderSuccessUpdate,
  };
};

export default useUpdateToCart;
