import { useDispatch, useSelector } from 'react-redux';
import SentryCategories from 'constants/sentryCategories';
import { get } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { onSuccessMutation, doPromiseAPI } from 'api/helpers';
import type { RootState } from 'store/reducers';
import { setNotificationRegistered } from 'store/user';

export const logFCMRegistrationQueryKey = 'FCMRegistration';

interface LogFCMRegistrationBody {
  token: string;
  device: string;
  allow: boolean;
  language: string;
}

interface UseLogFCMRegistrationResponse {
  status: MutationStatus;
  onLogFCMRegistration: (body: LogFCMRegistrationBody) => void;
}

const useRegisterNotifications = (): UseLogFCMRegistrationResponse => {
  const { axios } = useAxios();
  const { registerNotificationsAPI } = useAPIUrl();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const doLogFCMRegistrationToken = (body: LogFCMRegistrationBody) => {
    return doPromiseAPI(async (cancelToken) => {
      try {
        await axios.post(
          registerNotificationsAPI(),
          {
            deviceName: body.device,
            fcmToken: body.token,
            allowNotifications: body.allow ? 'Y' : 'N',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          { cancelToken }
        );
        return true;
      } catch (error) {
        return false;
      }
    });
  };

  const { status, mutate } = useMutation(doLogFCMRegistrationToken, {
    onSuccess: (successData, body) => {
      void onSuccessMutation(queryClient, logFCMRegistrationQueryKey, {
        userId,
      });
      Sentry.addBreadcrumb({
        category: SentryCategories.PUSH_NOTIFICATIONS,
        message: 'FCM registration token successful.',
        level: 'info',
        data: {
          userId,
          body,
        },
      });
      if (body.allow) {
        dispatch(setNotificationRegistered(true));
      }
    },
    onError: () => {
      Sentry.addBreadcrumb({
        category: SentryCategories.PUSH_NOTIFICATIONS,
        message: 'FCM registration token failed.',
        level: 'error',
        data: {
          userId,
        },
      });
    },
  });

  return {
    status,
    onLogFCMRegistration: (body: LogFCMRegistrationBody) => mutate(body),
  };
};

export default useRegisterNotifications;
