import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import {
  IonContent,
  IonMenu,
  IonRow,
  IonButtons,
  IonMenuToggle,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useInventoryPermissions from 'InventoryApp/util/useInventoryPermissions';
import {
  bulletinsURL,
  announcementsURL,
  feedbackURL,
  devToolsURL,
  settingsURL,
  manageFavoritesURL,
  homeInventoryURL,
  inventoryURL,
} from 'navigation';
import { AuthStateEvents, useAuthState } from 'providers/AuthStateProvider';
import { useToasts } from 'providers/ToastProvider';
import { useVault } from 'providers/VaultProvider';
import useSignin, { ModelLoginEnum } from 'api/user/useSignin';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { WhoAmIResponse } from 'models/Signin';
import UsernameStorage from 'storage/UsernameStorage';
import type { RootState } from 'store/reducers';
import { setUserData } from 'store/user';
import { APP_NAME } from 'utils/constants';
import defaultAppUrl from 'utils/defaultAppUrl';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import MIIcon from 'assets/MIIcon.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import AppVersion from 'components/AppVersion/AppVersion';
import Avatar from 'components/Avatar/Avatar';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Modal from 'components/Modal/Modal';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Select from 'components/Select/Select';
import Text from 'components/Text/Text';
import classes from './HomeMenu.module.scss';
import UserLocation from './UserLocation';

export const HOME_MENU_ID = 'home-menu';

const HomeMenu = ({
  disabled,
}: React.ComponentProps<typeof IonMenu> & IonicReactProps): JSX.Element => {
  const dispatch = useDispatch();
  const { send } = useAuthState();
  const { t } = useTranslation();
  const isDemoModeEligible = useFeatureFlags(FeatureFlagType.demoMode);
  const { setAuthToken, lockVault } = useVault();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const isWeb = Capacitor.getPlatform() === 'web';

  const displayname = get(userInfo, 'cn', 'User Name');
  const userId = get(userInfo, 'userid');
  const [signoutDialogIsOpen, setSignoutDialogIsOpen] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const [isOpenModelSelection, setIsOpenModelSelection] = useState(false);
  const [demoType, setDemoType] = useState<ModelLoginEnum>();
  const [username, setUsername] = useState<string>('');
  const workOrderFeatureFlag = useFeatureFlags(FeatureFlagType.workOrders);
  const workOrdersAccessControl = useAccessControls(
    AccessControlType.workOrders
  );
  const { hasInventoryPermission, hasVmiPermission } =
    useInventoryPermissions();

  const { signin } = useSignin();

  const tapCounter = 10;
  const [showDevToolsCounter, setShowDevToolsCounter] = useState<number>(1);

  const setDevToolsCounter = () => {
    if (showDevToolsCounter <= tapCounter) {
      setShowDevToolsCounter((prev) => prev + 1);
    }
  };

  const showDevTools = showDevToolsCounter > tapCounter;
  const { addToast } = useToasts();

  useEffect(() => {
    if (showDevTools && process.env.REACT_APP_DEV_TOOLS !== 'true') {
      addToast({
        text: t('common:enableDeveloperTools'),
        testid: 'enable-developertools-toast',
      });
    }
  }, [addToast, showDevTools, t]);

  const onSignout = async () => {
    if (isWeb) {
      await Preferences.clear();
    }
    await lockVault();
    setTimeout(() => {
      send({
        type: AuthStateEvents.LOGOUT,
        payload: {
          disableBiometricLogin: true,
        },
      });
    }, 300);
  };

  const { isMiProApp } = useChangeAppMode();
  const documentsAccessControl = useAccessControls(
    AccessControlType.viewDocuments
  );
  const replenishmentsAccessControl = useAccessControls(
    AccessControlType.viewItemReplenishment
  );
  const issueProcessingAccessControl = useAccessControls(
    AccessControlType.viewIssueProcessing
  );
  const url = defaultAppUrl({
    isMiProApp,
    workOrderFeatureFlag,
    workOrdersAccessControl,
    documentsAccessControl,
    replenishmentsAccessControl,
    issueProcessingAccessControl,
  });

  const toggleMode = () => {
    if (!demoMode) {
      setIsOpenModelSelection(true);
    }
    setDemoMode(!demoMode);
  };

  const setSignInType = async (model: string) => {
    setIsOpenModelSelection(false);

    setDemoType(model as ModelLoginEnum);

    const modelAR = model === ModelLoginEnum.modelAR;
    const modelBM = model === ModelLoginEnum.modelBM;
    const modelCAM = model === ModelLoginEnum.modelCAM;
    const { data: signInData, motionToken } = await signin({
      username,
      useToken: true,
      modelAR,
      modelBM,
      modelCAM,
    });

    const whoAmIData: WhoAmIResponse = {
      ...signInData,
    };
    if (motionToken) {
      await setAuthToken(motionToken || '');
    }
    dispatch(setUserData(whoAmIData));
  };

  const handleOnClickExternalLink = async (externalURL: string) => {
    await Browser.open({ url: externalURL });
  };

  useEffect(() => {
    const getUserName = async () => {
      const loggedInUsername = await UsernameStorage.get();
      setUsername(loggedInUsername || '');
    };
    void getUserName();
  }, []);

  return (
    <>
      <IonMenu
        className={classes.menu}
        side="end"
        maxEdgeStart={12}
        menuId={HOME_MENU_ID}
        contentId="main"
        type="overlay"
        disabled={disabled || !userId}
        data-testid="home-menu"
        swipeGesture={false}
      >
        <Header
          className={classes.homeHeader}
          showCartIcon={false}
          hideHomeMenu
          testid="menu"
          toolbar={
            <>
              <IonButtons slot="start" className={classes.avatar}>
                <Avatar />
              </IonButtons>
              <div className={classes.summary}>
                <IonRow className={classes.username}>
                  <Text
                    variant="title-info-card"
                    text={capitalize(displayname)}
                    testid="home-menu-username"
                  />
                </IonRow>
                <IonRow className={classes.location}>
                  <UserLocation />
                </IonRow>
              </div>
              <IonButtons slot="end">
                <IonMenuToggle>
                  <Button
                    className={classes.closeMenuButton}
                    variant="clear"
                    icon={findIcon('times')}
                    testid="home-menu-close-button"
                  />
                </IonMenuToggle>
              </IonButtons>
            </>
          }
        />
        <IonContent>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              {process.env.REACT_APP_FEATURE_MOTION_ANNOUNCEMENTS ===
                'true' && (
                <IonMenuToggle>
                  <ActionRow
                    text={t(['common:announcements'])}
                    href={concatRoutes(url, announcementsURL())}
                    testid="announcements-action"
                    leftButton={{
                      variant: 'clear',
                      className: classes.miProIconAnnouncementsButton,
                      children: (
                        <img
                          src={MIIcon}
                          className={classes.miProIconAnnouncements}
                          alt="Mi Pro"
                        />
                      ),
                      testid: 'mi-pro-icon-announcements',
                    }}
                  />
                </IonMenuToggle>
              )}
              <IonMenuToggle>
                <ActionRow
                  text={t(['common:settings'])}
                  href={concatRoutes(url, settingsURL())}
                  testid="settings-action"
                />
              </IonMenuToggle>
              {hasVmiPermission && (
                <IonMenuToggle>
                  <ActionRow
                    text={t(['inventory:homeVmi'])}
                    testid="inventory-action"
                    href={concatRoutes(inventoryURL(), homeInventoryURL())}
                  />
                </IonMenuToggle>
              )}
              {hasInventoryPermission && (
                <IonMenuToggle>
                  <ActionRow
                    text={t(['inventory:homeInventory'])}
                    testid="inventory-action"
                    href={concatRoutes(inventoryURL(), homeInventoryURL())}
                  />
                </IonMenuToggle>
              )}
              {isMiProApp && (
                <IonMenuToggle>
                  <ActionRow
                    text={t('favorites')}
                    href={concatRoutes(url, manageFavoritesURL())}
                    testid="favorites-action"
                  />
                </IonMenuToggle>
              )}
              {process.env.REACT_APP_FEATURE_BULLETINS === 'true' && (
                <IonMenuToggle>
                  <ActionRow
                    text={t(['common:bulletins'])}
                    href={concatRoutes(url, bulletinsURL())}
                    testid="announcements-action"
                  />
                </IonMenuToggle>
              )}
              {isMiProApp && (
                <>
                  <IonMenuToggle>
                    <ActionRow
                      text={t('miConnectSalesHub')}
                      onClick={() =>
                        handleOnClickExternalLink(
                          process.env.REACT_APP_SALES_HUB_URL as string
                        )
                      }
                      testid="sales-hub"
                      icon="external-link-alt"
                      isExternalLink
                    />
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <ActionRow
                      text={t('miProHowToResources')}
                      onClick={() =>
                        handleOnClickExternalLink(
                          process.env.REACT_APP_HOW_TO_RESOURCES_URL as string
                        )
                      }
                      testid="how-to-resources"
                      icon="external-link-alt"
                      isExternalLink
                    />
                  </IonMenuToggle>
                </>
              )}
              <IonMenuToggle>
                <ActionRow
                  text={t('feedback:provideFeedback')}
                  href={concatRoutes(url, feedbackURL())}
                  testid="feedback-action"
                />
              </IonMenuToggle>
              {(process.env.REACT_APP_DEV_TOOLS === 'true' || showDevTools) && (
                <IonMenuToggle>
                  <ActionRow
                    text="Developer tools"
                    href={concatRoutes(url, devToolsURL())}
                    testid="developer-tools"
                  />
                </IonMenuToggle>
              )}
              <IonMenuToggle>
                <ActionRow
                  className={classes.primary}
                  onClick={() => setSignoutDialogIsOpen(true)}
                  text={t('signOut')}
                  testid="signout-action"
                />
              </IonMenuToggle>
              {isDemoModeEligible && (
                <IonMenuToggle>
                  <ActionRow
                    className={classes.demoModeRow}
                    onClick={() => toggleMode()}
                    text={t('demoModeSwitch')}
                    testid="demoMode-action"
                  />
                </IonMenuToggle>
              )}
            </div>

            <AppVersion setDevToolsCounter={setDevToolsCounter} />
          </div>
        </IonContent>
      </IonMenu>
      <Modal
        className={classes.modal}
        isOpen={isOpenModelSelection}
        setIsOpen={setIsOpenModelSelection}
        title="Demo Mode Selection"
        testid="demo-mode-selection-model"
      >
        <Select
          className={classes.modelSelect}
          label={t('login:modelSelect')}
          name="model"
          options={[
            { id: ModelLoginEnum.modelAR, text: t('login:modelAR') },
            { id: ModelLoginEnum.modelBM, text: t('login:modelBM') },
            { id: ModelLoginEnum.modelCAM, text: t('login:modelCAM') },
          ]}
          value={demoType}
          setValue={(e) => {
            void setSignInType(e);
          }}
          testid="model-input"
        />
      </Modal>
      <ConfirmDialog
        isOpen={signoutDialogIsOpen}
        setIsOpen={setSignoutDialogIsOpen}
        title={t('login:signOutTitle', { app: APP_NAME })}
        text={t('login:signOutText', { app: APP_NAME })}
        primaryText={t('login:signOutPrimary')}
        secondaryText={t('login:signOutSecondary')}
        onSecondaryClick={onSignout}
        testid="signout-modal"
      />
    </>
  );
};

export default HomeMenu;
