import { useMemo } from 'react';
import { toString } from 'lodash';
import { choose } from 'common/utils/logicHelpers';
import { endOfDay, isAfter, isToday, startOfDay, subWeeks } from 'date-fns';
import type { ActionCardActivity } from 'models/ActivityModels';

interface ActivitiesGroupCountsRepsonse {
  groupCounts: number[];
}

const useActivitiesGroupCounts = (
  activities: ActionCardActivity[],
  visitsTab = false
): ActivitiesGroupCountsRepsonse => {
  const groupCounts = useMemo((): number[] => {
    let future = 0;
    let today = 0;
    let lastTwoWeeks = 0;
    let older = 0;

    activities.forEach((activity) => {
      const showAfterDate = new Date(
        toString(
          choose(
            visitsTab,
            activity?.extendedInfo?.visitDate,
            activity?.showAfterISO
          )
        )
      );

      // future group
      if (isAfter(showAfterDate, endOfDay(new Date()))) {
        future += 1;
        return;
      }

      // today group
      if (isToday(showAfterDate)) {
        today += 1;
        return;
      }

      // last two weeks group
      if (isAfter(showAfterDate, startOfDay(subWeeks(new Date(), 2)))) {
        lastTwoWeeks += 1;
        return;
      }

      // older group (fallback)
      older += 1;
    });
    return [future, today, lastTwoWeeks, older];
  }, [activities, visitsTab]);
  return {
    groupCounts,
  };
};

export default useActivitiesGroupCounts;
