import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString } from 'lodash';
import { ifRender, or } from 'common/utils/logicHelpers';
import { searchLocationDetailURL, searchURL } from 'navigation';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Address from 'components/Contacts/Address/Address';
import { PhoneCallRow } from 'components/Contacts/PhoneNumber/PhoneNumber';
import { EmailRow } from 'components/Email/Email';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './ShopModal.module.scss';

interface ShopDetails {
  shopMiLoc: string;
  shopName: string;
  shopAddr1: string;
  shopAddr2: string;
  shopAddr3: string;
  shopCity: string;
  shopState: string;
  shopZip: string;
  country: string;
  shopPhone: string;
  shopEmail: string;
}
interface ShopModalProps {
  repairShopModalOpen: boolean;
  setRepairShopModalOpen: (val: boolean) => void;
  emailModalTestId?: string;
  shop: Partial<ShopDetails>;
}

const ShopModal = (props: ShopModalProps): JSX.Element => {
  const {
    shop,
    repairShopModalOpen,
    setRepairShopModalOpen,
    emailModalTestId,
  } = props;

  const {
    shopMiLoc,
    shopName,
    shopAddr1,
    shopAddr2,
    shopAddr3,
    shopCity,
    shopState,
    shopZip,
    country,
    shopPhone,
    shopEmail,
  } = shop;
  const { t } = useTranslation();
  const shopLocationHref = concatRoutes(
    searchURL(),
    searchLocationDetailURL(shopMiLoc)
  );
  const [sendEmailModal, setSendEmailModal] = useState(false);

  const dismissShopModal = () => setRepairShopModalOpen(false);
  const namespace = 'common:ShopQuote-ShopModal';
  return (
    <>
      <SheetModal
        withRightCloseButton
        isOpen={repairShopModalOpen}
        setIsOpen={setRepairShopModalOpen}
        testid="shop-quote-form-shop-modal"
        title={`${toString(shopMiLoc)} - ${toString(shopName)}`}
        contentClass={classes.shopModal}
      >
        {ifRender(
          shopAddr2 && shopCity && shopState,
          <Address
            className={classes.address}
            onClick={() => dismissShopModal()}
            address={{
              addrLine1: shopAddr1,
              addrLine2: shopAddr2,
              addrLine3: shopAddr3,
              city: shopCity,
              state: shopState,
              zip: shopZip,
              country,
            }}
          />
        )}
        {ifRender(
          shopPhone,
          <PhoneCallRow
            className={classes.modalText}
            phoneNumber={shopPhone as string}
            testid="shop-phone"
            onClick={() => dismissShopModal()}
          />
        )}
        {ifRender(
          shopEmail,
          <EmailRow
            className={classes.modalText}
            emailAddress={shopEmail as string}
            testid="shop-email"
            onClick={() => {
              setSendEmailModal(true);
              dismissShopModal();
            }}
          />
        )}
        <Button
          className={classNames(classes.linkButton, classes.spacer)}
          variant="secondary"
          text={t(`${namespace}:viewShopDetails`)}
          testid="view-shop-button"
          href={shopLocationHref}
          onClick={() => dismissShopModal()}
        />
      </SheetModal>
      <SendEmailModal
        searchType="motionLocation"
        miLoc={shopMiLoc}
        id={shopMiLoc}
        defaultRecipients={[
          { name: shopName, miLoc: shopMiLoc, email: shopEmail },
        ]}
        canRemoveRecipients={false}
        isOpen={sendEmailModal}
        setIsOpen={setSendEmailModal}
        title={t(`${namespace}:sendEmail`)}
        testid={or(emailModalTestId, 'send-email-modal')}
      />
    </>
  );
};

export default ShopModal;
