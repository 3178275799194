import React, { useImperativeHandle, useRef } from 'react';
import { map, toString } from 'lodash';
import VisitForm from 'ActivitiesApp/components/VisitForm/VisitForm';
import classes from 'ActivitiesApp/components/VisitForm/VisitForm.module.scss';
import {
  CustomerVisitTypeEnum,
  CustomerVisitIconEnum,
  CustomerVisitColorEnum,
  CustomerVisitFilterEnum,
} from 'ActivitiesApp/models/CustomerVisit';
import { goToShareActivityByEmail } from 'ActivitiesApp/navigation/navigationHelpers';
import type { ActivityComponentRef } from 'ActivitiesApp/pages/ActivityDetail';
import { isVisit } from 'ActivitiesApp/utils/helpers';
import { choose } from 'common/utils/logicHelpers';
import { isFuture, isToday } from 'date-fns';
import type { Contact } from 'models/Contact';
import { formatSnoozeDate, parseDate } from 'utils/date';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getCustomerVisitConfig = ({
  loggedInUserId,
  activityVersion,
  activityData,
  defaultFirstLine,
  i18n,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { extendedInfo, fields, isForCorpAcct, customerName } = activityData;
  const activityDate =
    toString(activityData?.extendedInfo?.visitDateWithZimeZoneCreated) ||
    activityData.creationTimestamp;
  const isCurrentDay = isToday(parseDate(activityDate));
  const isFutureDay = isFuture(parseDate(activityDate));
  const visitDate = formatSnoozeDate(activityDate, i18n.language);
  const visitEvent = isVisit(activityData.extendedInfo?.visitType);
  const customerVisitText = isCurrentDay
    ? t(visitEvent ? 'todayVisit' : 'todayCall')
    : t(visitEvent ? 'anotherDayVisit' : 'anotherDayCall', { visitDate });
  const visitorUserId = fields?.find(
    (item) => item.name === 'Visitor Employee User ID'
  )?.value;
  const visitData = {
    contacts: extendedInfo?.contacts,
    notebookText: extendedInfo?.notebookText,
    newOpportunity: extendedInfo?.newOpportunity,
    visitor: {
      name:
        fields?.find((item) => item.name === 'Visitor Employee Name')?.value ||
        visitorUserId ||
        activityData.userFullName,
    },
  };
  const customerContactNames = map(
    extendedInfo?.contacts as Contact[],
    (contact) => toString(contact.contactName)
  ).join(', ');

  const customerContactCount = customerContactNames.length;
  let description: string;
  const customerVisitedByNonRep =
    activityVersion === `${CustomerVisitTypeEnum.customerVisitedByNonRep}-1`;

  const childKeySuffix = isForCorpAcct ? 'Child' : '';
  let customerVisitByOthers = activityData.userId !== loggedInUserId;
  if (visitorUserId) {
    customerVisitByOthers = visitorUserId !== loggedInUserId;
  }

  if (customerVisitByOthers) {
    const descriptionLabelKey = toString(
      choose(
        visitEvent,
        choose(
          isFutureDay,
          'willVisitDescriptionNonRep',
          'visitDescriptionNonRep'
        ),
        choose(
          isFutureDay,
          'willCallDescriptionNonRep',
          'callDescriptionNonRep'
        )
      )
    );
    description = t(`${descriptionLabelKey}${childKeySuffix}`, {
      visitor: visitData.visitor.name,
      customerContact: customerContactNames,
      companyName: customerName,
      count: customerContactCount,
      date: visitDate,
    });
  } else {
    const descriptionLabelKey = toString(
      choose(
        visitEvent,
        choose(isFutureDay, 'willVisitDescription', 'visitDescription'),
        choose(isFutureDay, 'willCallDescription', 'callDescription')
      )
    );
    description = t(`${descriptionLabelKey}${childKeySuffix}`, {
      visitor: visitData.visitor.name,
      customerContact: customerContactNames,
      companyName: customerName,
      count: customerContactCount,
      date: visitDate,
    });
  }

  switch (activityVersion) {
    case `${CustomerVisitTypeEnum.customerVisit}-1`:
    case `${CustomerVisitTypeEnum.customerVisitedByNonRep}-1`:
      return {
        card: {
          defaultTitle: t(visitEvent ? 'visitLabel' : 'customerCallTitle'),
          body: {
            description: t('addNotesVisit'),
            lines: [
              ...defaultFirstLine,
              {
                description,
                highlight: customerContactNames,
              },
              {
                description: toString(visitData.notebookText),
                className: classes.cardDescription,
              },
            ],
          },
        },
        modal: {
          title: customerVisitText,
          defaultData: visitData,
          hideSaveButton: !customerVisitedByNonRep,
          refetchActivity: true,
          customContentWithRef: React.forwardRef((props, outerRef) => {
            const componentRef = useRef<ActivityComponentRef>(null);

            useImperativeHandle(
              outerRef,
              () => ({ onSubmit: componentRef.current?.onSubmit }),
              []
            );

            return (
              <VisitForm
                ref={componentRef}
                historyId={activityData.historyId}
                userId={activityData.userId}
                activityData={visitData}
                readonly={activityData?.done === 'Y'}
                activity={activityData}
                customerVisitedByNonRep={customerVisitedByNonRep}
                customerVisitByOthers={customerVisitByOthers}
                isSameUser={activityData.userId === loggedInUserId}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }),
          headerActions: (didChange) => ({
            title: t(
              visitEvent ? 'activities:shareVisit' : 'activities:shareCall'
            ),
            options: [
              {
                text: t(
                  didChange ? 'common:sendEmailSave' : 'common:sendEmail'
                ),
                testid: 'send-email-btn',
                buildHref: (historyId: string) =>
                  goToShareActivityByEmail({
                    miLoc: activityData.custMiLoc,
                    customerId: activityData.custNo,
                    userId: activityData.userId,
                    historyId: historyId || toString(activityData.historyId),
                    activityType: activityData.eventTagName,
                    reset: true,
                  }),
              },
            ],
          }),
        },
      };
    default:
      return undefined;
  }
};

export const getCustomerVisitData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'CustomerVisit':
      return {
        cardType: CustomerVisitTypeEnum.customerVisit,
        title: t('customerVisitText'),
        icon: CustomerVisitIconEnum.customerVisit,
        color: CustomerVisitColorEnum.customerVisit,
      };
    case 'CustomerVisitedByNonRep':
      return {
        cardType: CustomerVisitTypeEnum.customerVisitedByNonRep,
        title: t('customerVisitText'),
        icon: CustomerVisitIconEnum.customerVisit,
        color: CustomerVisitColorEnum.customerVisit,
      };
    default:
      return undefined;
  }
};

export const getCustomerVisitFilterIcon = (
  type: string
): CustomerVisitIconEnum | undefined => {
  switch (type) {
    case CustomerVisitFilterEnum.customerVisit:
      return CustomerVisitIconEnum.customerVisit;
    default:
      return undefined;
  }
};
