import type { IconName } from '@fortawesome/fontawesome-svg-core';
import 'api/interceptors';
import type { SearchItemType } from 'models/Search';

export const API_URL = process.env.REACT_APP_API_URL || '/api';
const BULLETINS_URL = process.env.REACT_APP_BULLETINS_URL || '/bulletins';

export const isAPIUrl = (url: string | undefined): boolean =>
  !!url?.startsWith(API_URL);
export const isBulletinsAPIUrl = (url: string | undefined): boolean =>
  !!url?.startsWith(BULLETINS_URL);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAPIUrl = () => {
  return {
    // #region session
    loginAPI: (): string => `${API_URL}/auth/login`,

    loginTokenAPI: (): string => `${API_URL}/auth/loginToken`,

    whoAmIAPI: (): string => `${API_URL}/auth/whoami`,
    // #endregion

    // #region user
    pingAPI: (): string => `${API_URL}/Ping`,

    accessControlAPI: (id: string): string =>
      `${API_URL}/auth/accesscontrol/${id}`,

    redirectTokenAPI: (
      miLoc: string,
      custNumber: string,
      ocn?: string
    ): string =>
      `${API_URL}/redirect/${miLoc}/${custNumber}${ocn ? `/${ocn}` : ''}`,

    avatarAPI: (employeeId: string): string =>
      `${API_URL}/employee/${employeeId}/avatar`,

    registerNotificationsAPI: (): string => `${API_URL}/notifications`,

    markNotificationsAsReadAPI: (): string =>
      `${API_URL}/notifications/markRead`,

    unreadNotificationsCountAPI: (): string =>
      `${API_URL}/notifications/unreadCount`,

    notificationsSettingsAPI: (): string =>
      `${API_URL}/notifications/userConfig`,

    configTypeAPI: (configType: string): string =>
      `${API_URL}/userConfig/${configType}`,

    feedbackAPI: (): string => `${API_URL}/feedback/v2`,

    emailAPI: (): string => `${API_URL}/email`,

    findDataCodesAPI: (codeType: string): string =>
      `${API_URL}/codes/${codeType}`,
    // #endregion

    // #region announcements
    findAnnouncementsAPI: (paramsString: string): string =>
      `${API_URL}/announcements/all?${paramsString}`,

    getAnnouncementAPI: (fileName: string, paramsString: string): string =>
      `${API_URL}/announcements/${fileName}?${paramsString}`,

    markAnnouncementAsReadAPI: (fileName: string): string =>
      `${API_URL}/announcements/markRead/${fileName}`,

    markAllAnnouncementsAsReadAPI: (): string =>
      `${API_URL}/announcements/markAllRead`,
    // #endregion

    // #region features
    findFeaturesAPI: (): string => `${API_URL}/announcements/features`,
    // #endregion

    // #region contacts
    findContactsAPI: (
      type: SearchItemType,
      miLoc: string,
      id: string,
      paramsString?: string
    ): string =>
      `${API_URL}/${type}/${miLoc}/${id}/contacts${
        paramsString ? `?${paramsString}` : ''
      }`,

    contactAPI: (
      type: SearchItemType,
      miLoc: string,
      id: string,
      seq: string
    ): string => `${API_URL}/${type}/${miLoc}/${id}/contacts/${seq}`,

    inviteContactAPI: (
      type: SearchItemType,
      miLoc: string,
      id: string,
      seq: string
    ): string => `${API_URL}/${type}/${miLoc}/${id}/contacts/${seq}/invite`,
    // #endregion

    // #region customers
    getCustomerAPI: (miLoc: string, id: string): string =>
      `${API_URL}/customer/${miLoc}/${id}`,

    findCustomersAPI: (paramsString: string): string =>
      `${API_URL}/customer?${paramsString}`,

    getAccountsReceivableAPI: (miLoc: string, id: string): string =>
      `${API_URL}/customer/${miLoc}/${id}/accounts-receivable`,

    getAgreementInformationAPI: (miLoc: string, id: string): string =>
      `${API_URL}/customer/${miLoc}/${id}/agreement`,

    getTeamToShareForCustomerAPI: (miLoc: string, id: string): string =>
      `${API_URL}/customer/${miLoc}/${id}/teamToShare`,

    updatePickTwelveIconAPI: (
      employeeId: string,
      miLoc: string,
      id: string,
      icon: IconName
    ): string =>
      `${API_URL}/employee/${employeeId}/pick12/${miLoc}/${id}/${icon}`,

    findRecentSearchesAPI: (
      miLoc: string,
      id: string,
      paramsString: string
    ): string =>
      `${API_URL}/customer/${miLoc}/${id}/activity/web?${paramsString}`,

    findRecentProductsAPI: (
      miLoc: string,
      id: string,
      paramsString: string
    ): string =>
      `${API_URL}/customer/${miLoc}/${id}/activity/product?${paramsString}`,

    findWebActivitySummaryAPI: (
      miLoc: string,
      id: string,
      paramsString: string
    ): string =>
      `${API_URL}/customer/${miLoc}/${id}/activity/web/summary?${paramsString}`,

    findWebActivityContactsAPI: (
      miLoc: string,
      id: string,
      paramsString: string
    ): string =>
      `${API_URL}/customer/${miLoc}/${id}/activity/web/contacts?${paramsString}`,

    getProductAPIv2: (miLoc: string, id: string, seq: string): string =>
      `${API_URL}/product/${miLoc}/${id}/${seq}/detail/v2`,

    findProductTransitAPI: (miLoc: string, id: string, seq: string): string =>
      `${API_URL}/product/${miLoc}/${id}/${seq}/transit`,

    logVisitAPI: (employeeId: string, miLoc: string, id: string): string =>
      `${API_URL}/visit/${employeeId}/${miLoc}/${id}`,

    findSICCodes: (paramsString: string) =>
      `${API_URL}/fastfind/sicCodes?${paramsString}`,

    updateSICCode: (miLoc: string, id: string, code: string): string =>
      `${API_URL}/customer/${miLoc}/${id}/alternateSICUpdate?alternateSIC=${code}`,
    // #endregion

    // #region suppliers
    getSupplierAPI: (miLoc: string, id: string): string =>
      `${API_URL}/supplier/${miLoc}/${id}`,

    findSuppliersAPI: (paramsString: string): string =>
      `${API_URL}/supplier?${paramsString}`,

    findEmployeesAPI: (paramsString: string): string =>
      `${API_URL}/employee/search?${paramsString}`,

    findCustomerContactsAPI: (paramsString: string): string =>
      `${API_URL}/customer/contacts?${paramsString}`,

    findSupplierContactsAPI: (paramsString: string): string =>
      `${API_URL}/supplier/contacts?${paramsString}`,

    getEmployeeAPI: (paramString: string): string =>
      `${API_URL}/employee/${paramString}`,

    findActiveEmployeesAPI: (): string => `${API_URL}/employee/fetch-active`,

    getLocationAPI: (paramString: string): string =>
      `${API_URL}/location/${paramString}`,

    findLocationsAPI: (paramsString: string): string =>
      `${API_URL}/location/search?${paramsString}`,

    getInventoryAnalysisAPI: (
      miLoc: string,
      id: string,
      paramsString: string
    ): string =>
      `${API_URL}/supplier/${miLoc}/${id}/inventory-analysis?${paramsString}`,

    getVendorIncreaseLettersAPI: (miLoc: string, id: string): string =>
      `${API_URL}/supplier/${miLoc}/${id}/vendor-increase-letters`,

    getDCApprovedVendorsAPI: (miLoc: string, id: string): string =>
      `${API_URL}/supplier/${miLoc}/${id}/approved-vendors`,

    getSupplierNotebooksAPI: (
      miLoc: string,
      mfrCtlNo: string,
      supLocNo: string,
      nbType: string
    ): string =>
      `${API_URL}/notebook/supplierNotebook/${miLoc}/${mfrCtlNo}/${supLocNo}/${nbType}`,
    // #endregion

    // #region favorites
    findFavoriteCustomersAPI: (userId: string, paramsString: string): string =>
      `${API_URL}/user/${userId}/favorites/customer?${paramsString}`,

    findFavoriteSuppliersAPI: (userId: string, paramsString: string): string =>
      `${API_URL}/user/${userId}/favorites/supplier?${paramsString}`,

    addFavoritesAPI: (type: SearchItemType, userId: string): string =>
      `${API_URL}/user/${userId}/favorites/${type}/addFavorites`,

    removeFavoritesAPI: (type: SearchItemType, userId: string): string =>
      `${API_URL}/user/${userId}/favorites/${type}/deleteFavorites`,
    // #endregion

    // #region reports
    getReportSummaryAPI: (paramsString: string): string =>
      `${API_URL}/reports/summary?${paramsString}`,

    getReportDrillDownAPI: (paramsString: string): string =>
      `${API_URL}/reports/drilldown?${paramsString}`,

    getProductGroups: (): string => `${API_URL}/codes/productgroups`,

    getPickTwelveAPI: (employeeId: string): string =>
      `${API_URL}/employee/${employeeId}/pick12`,

    getCalendarAPI: (paramsString: string): string =>
      `${API_URL}/calendar?${paramsString}`,

    getOpenQuotesAPI: (paramsString: string): string =>
      `${API_URL}/reporting/sales/openquotes?${paramsString}`,

    // #endregion

    // #region report v2
    getNewSalesReportAPI: (paramsString: string): string =>
      `${API_URL}/reportsv2/sales?${paramsString}`,

    getNewDigitalSalesReportAPI: (paramsString: string): string =>
      `${API_URL}/reportsv2/webperformance?${paramsString}`,

    getEndOfMonthAPI: (paramsString: string): string =>
      `${API_URL}/reportsv2/endofmonth?${paramsString}`,

    getPick12ReportAPI: (paramsString: string): string =>
      `${API_URL}/reportsv2/pick12?${paramsString}`,

    getLocationPick12ReportAPI: (paramsString: string): string =>
      `${API_URL}/reportsv2/locationpick12?${paramsString}`,

    // #endregion

    // #region leaderboard report
    getLeaderboardAPI: (year: number, paramString: string): string =>
      `${API_URL}/leaderboard/${year}?${paramString}`,

    getLeaderBoardDetails: (year: number, userId: string): string =>
      `${API_URL}/leaderboard/${year}/${userId}`,
    // #endregion

    // #region work-orders
    findWorkOrdersAPI: (miLoc: string, paramString: string): string =>
      `${API_URL}/workorder/${miLoc}?${paramString}`,
    // #endregion

    // #region documents
    findTemplatesAPI: (): string => `${API_URL}/document/template`,

    getTemplateAPI: (id: string): string =>
      `${API_URL}/document/template/${id}`,

    findDocumentsAPI: (paramsString: string): string =>
      `${API_URL}/document/report?${paramsString}`,

    getReportAPI: (id: string): string =>
      `${API_URL}/document/report/${id}?includeImages=true`,

    getReportCoverPageAPI: (id: string): string =>
      `${API_URL}/document/report/${id}/coverPage`,

    createReportAPI: (): string => `${API_URL}/document/report/coverPage`,

    updateSectionAPI: (): string => `${API_URL}/document/report/values`,

    reportImagesAPI: (): string => `${API_URL}/document/report/images`,

    reportValuesListAPI: (list: string): string =>
      `${API_URL}/document/report/${list}`,

    updateReportSignatureAPI: (): string =>
      `${API_URL}/document/data/reportsignature`,

    findManufacturersAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/manufacturer?${paramsString}`,

    findArtAssetsAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/artAsset?${paramsString}`,

    findShopsAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/shop?${paramsString}`,

    findBranchesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/branch?${paramsString}`,

    findSitesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/site?${paramsString}`,

    findMachinesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/machine?${paramsString}`,

    findWorkOrderNumAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/workOrder?${paramsString}`,

    findVasCodesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/vasCodes?${paramsString}`,

    findWorkTasksAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/workTask?${paramsString}`,

    sendDocumentEmailAPI: (): string => `${API_URL}/email/documentEmail?`,
    // #endregion

    // #region scanner
    scannerUserProfileAPI: (miLoc: string): string =>
      `${API_URL}/storeroom/scanner/scannerUserProfile/v2?miLoc=${miLoc}`,

    itemsAPISearch: (paramsString: string): string =>
      `${API_URL}/storeroom/scanner/ItemProfileSearch?${paramsString}`,

    storeroomsAPI: (paramsString: string): string =>
      `${API_URL}/storeroom/scanner/storeroomProfile/v2?${paramsString}`,

    chargeBackProfilesAPI: (miLoc: string): string =>
      `${API_URL}/storeroom/scanner/chargeBackProfile/v2?miLoc=${miLoc}`,

    chargeBackValuesAPI: (paramsString: string): string =>
      `${API_URL}/storeroom/scanner/validateChargeBack?${paramsString}`,

    replenishmentAPI: (): string => `${API_URL}/storeroom/replenishments/v2`,

    issueAPI: (): string => `${API_URL}/storeroom/issues/v2`,
    // #endregion

    // #region documents database
    getReportsDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/report?${paramsString}`,

    getShopsDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/shops?${paramsString}`,

    getWorkOrdersDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/v2/workorders?${paramsString}`,

    getSitesDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/sites?${paramsString}`,

    getMachinesDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/machines?${paramsString}`,

    getTemplatesDatabaseAPI: (): string => `${API_URL}/document/data/template`,

    getBranchesDatabaseAPI: (paramsString: string): string =>
      `${API_URL}/document/data/branches?${paramsString}`,

    // #endregion

    // #region events
    findActivitiesAPI: (paramsString: string): string =>
      `${API_URL}/event?${paramsString}`,

    findActivitiesAPIv2: (paramsString: string): string =>
      `${API_URL}/event/v2?${paramsString}`,

    findActivityFiltersAPI: (userId: string, paramsString: string): string =>
      `${API_URL}/action/${userId ? `${userId}/` : ''}v2?${paramsString}`,

    findActivityNotesAPI: (paramsString: string): string =>
      `${API_URL}/notebook/eventNotebook/getNotebooks?${paramsString}`,

    deleteActivityNotesAPI: (id: number, deleteFlag: string): string =>
      `${API_URL}/notebook/eventNotebook/delete/${id}/${deleteFlag}`,

    updateActivityNotesAPI: (id: number): string =>
      `${API_URL}/notebook/eventNotebook/update/${id}`,

    addActivityNoteAttachmentsAPI: (): string =>
      `${API_URL}/notebook/eventNotebook/attachments/saveImages`,

    deleteActivityNoteAttachmentsAPI: (): string =>
      `${API_URL}/notebook/eventNotebook/attachments/deleteImages`,

    addActivityNotesAPI: (miLoc: string, historyId: number): string =>
      `${API_URL}/notebook/eventNotebook/add/${miLoc}/${historyId}`,

    getActivityNoteAPI: (id: string): string =>
      `${API_URL}/notebook/eventNotebook/get/${id}`,

    getActivityAPI: (historyId: string, userId: string): string =>
      `${API_URL}/event/${historyId}/${userId}`,

    updateActivityAPI: (historyId: string): string =>
      `${API_URL}/event/${historyId}`,

    updateActivitiesAPI: (): string => `${API_URL}/event/events`,

    markActivityReadAPI: (): string => `${API_URL}/event/markRead`,

    markAllActivitiesAsReadAPI: (userId: string): string =>
      `${API_URL}/event/markAllActivitiesRead/${userId}`,

    eventConfigsAPI: (userId: string): string =>
      `${API_URL}/employee/${userId}/event/config`,

    rateVisitAPI: (historyId: string): string =>
      `${API_URL}/visit/rate/${historyId}`,

    customerVisitAPI: (userId: string, miLoc: string, id: string): string =>
      `${API_URL}/visit/${userId}/${miLoc}/${id}`,

    updatePriceOverrideAPI: (
      miLoc: string,
      ocn: string,
      lineNo: string
    ): string => `${API_URL}/priceoverride/order/${miLoc}/${ocn}/${lineNo}`,

    updateSourcingOverrideAPI: (
      miLoc: string,
      ocn: string,
      lineNo: string
    ): string => `${API_URL}/sourcingoverride/${miLoc}/${ocn}/${lineNo}`,

    updateTaskAPI: (taskId: number): string => `${API_URL}/task/${taskId}`,
    // #endregion

    // #region sales plays
    findSalesPlaysAPI: (paramsString: string): string =>
      `${API_URL}/opportunities?${paramsString}`,

    getSalesPlayAPI: (externalSource: string, externalId: string): string =>
      `${API_URL}/opportunities/${externalSource}/${externalId}`,

    updateSalesPlayAPI: (externalSource: string, externalId: string): string =>
      `${API_URL}/opportunities/${externalSource}/${externalId}`,

    // #endregion

    // #region notes
    notebooksAPI: (nbType: string, id: string): string =>
      `${API_URL}/notebook/${nbType}/${id}`,

    deleteNotebookAPI: (
      nbType: string,
      id: string,
      paramsString: string
    ): string => `${API_URL}/notebook/${nbType}/${id}?${paramsString}`,

    notesAPI: (miLoc: string, id: string, paramsString: string): string =>
      `${API_URL}/customer/${miLoc}/${id}/notes?${paramsString}`,
    // #endregion

    // #region attachments
    getAttachmentThumbnailAPI: (
      domain: string,
      miLoc: string,
      ctlNo: string,
      lineNo: string,
      seqNo: string,
      fileName: string,
      width: number,
      height: number
    ): string =>
      `${API_URL}/attachment/${domain}/${miLoc}/${ctlNo}/${lineNo}/${seqNo}/${fileName}/thumbnail?width=${width}&height=${height}`,

    getAttachmentImageAPI: (
      domain: string,
      miLoc: string,
      ctlNo: string,
      lineNo: string,
      seqNo: string,
      fileName: string
    ): string =>
      `${API_URL}/attachment/${domain}/${miLoc}/${ctlNo}/${lineNo}/${seqNo}/${fileName}`,

    findAttachmentsAPI: (
      domain: string,
      miLoc?: string,
      ctlNo?: string,
      lineNo?: string
    ): string =>
      `${API_URL}/attachment/${domain}${miLoc ? `/${miLoc}` : ''}${
        ctlNo ? `/${ctlNo}` : ''
      }${lineNo ? `/${lineNo}` : ''}`,

    uploadAttachmentAPI: (
      domain: string,
      miLoc: string,
      ctlNo: string,
      lineNo: string
    ): string => `${API_URL}/attachment/${domain}/${miLoc}/${ctlNo}/${lineNo}`,

    removeAttachmentAPI: (
      domain: string,
      miLoc: string,
      ctlNo: string,
      lineNo: string,
      seqNo: string
    ): string =>
      `${API_URL}/attachment/${domain}/${miLoc}/${ctlNo}/${lineNo}/${seqNo}`,

    updateAttachmentAPI: (
      domain: string,
      miLoc: string,
      ctlNo: string,
      lineNo: string,
      seqNo: string,
      fileName: string
    ): string =>
      `${API_URL}/attachment/${domain}/${miLoc}/${ctlNo}/${lineNo}/${seqNo}/${fileName}`,
    // #endregion

    // #region cost savings
    findCostSavingsAPI: (paramsString: string): string =>
      `${API_URL}/costSavings?${paramsString}`,

    getCostSavingsDetailsAPI: (mioc: string, vasCtNo: string): string =>
      `${API_URL}/costSavings/${mioc}/${vasCtNo}`,

    findCostCodesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/costSavingsCodes?${paramsString}`,

    getCostTemplateAPI: (paramString: string): string =>
      `${API_URL}/costSavings/template?${paramString}`,

    findProductsAPI: (paramString: string): string =>
      `${API_URL}/product/search?${paramString}`,

    submitCostSavingsAPI: (): string => `${API_URL}/costSavings`,
    // #endregion

    // #region product search
    findProductOpportunitiesAPI: (
      miLoc: string,
      customerNo: string,
      itemNo: string,
      paramString: string
    ): string =>
      `${API_URL}/product/${miLoc}/${customerNo}/${itemNo}/interchange?${paramString}`,

    findProductSourcesAPI: (
      miLoc: string,
      customerNo: string,
      itemNo: string,
      paramString: string
    ): string =>
      `${API_URL}/product/${miLoc}/${customerNo}/${itemNo}/sources?${paramString}`,

    findOrdersAPI: (miLoc: string, customerNo: string, paramString: string) =>
      `${API_URL}/order/${miLoc}/${customerNo}/search?${paramString}`,

    findGlobalOrdersAPI: (paramsString: string): string =>
      `${API_URL}/order/search?${paramsString}`,

    getOrderAPI: (miLoc: string, orderCtlNo: string) =>
      `${API_URL}/order/${miLoc}/${orderCtlNo}/v2`,

    findTransmissionLogsAPI: (paramString: string) =>
      `${API_URL}/order/transmissionLog?${paramString}`,

    addToCartAPI: () => `${API_URL}/order/items`,

    addItemSourcingAPI: () => `${API_URL}/order/sourcing`,

    saveAsQuoteAPI: () => `${API_URL}/order/saveAsQuote`,

    saveAsOrderAPI: () => `${API_URL}/order/saveAsOrder`,

    createPriceOverrideAPI: () => `${API_URL}/order/items/createPriceOverride`,

    deleteOrderAPI: () => `${API_URL}/order/deleteOrder`,

    findLostSalesAPI: (paramString: string) =>
      `${API_URL}/fastfind/lostSale?${paramString}`,

    findShippingChargesAPI: (paramString: string) =>
      `${API_URL}/fastfind/shippingCharges?${paramString}`,

    findShippingMethodAPI: (paramString: string) =>
      `${API_URL}/fastfind/shippingMethod?${paramString}`,

    findCarrierAPI: (paramString: string) =>
      `${API_URL}/fastfind/carrierCode?${paramString}`,

    findGroups2DigitsAPI: (paramString: string) =>
      `${API_URL}/order/productGroupsByMFR?${paramString}`,

    findGroups4DigitsAPI: (paramString: string) =>
      `${API_URL}/order/productGroupsByTwoDigits?${paramString}`,

    findRepairShopsAPI: (miLoc: string, paramString: string) =>
      `${API_URL}/location/${miLoc}/shops?${paramString}`,

    findNotebookTemplateAPI: (paramString: string) =>
      `${API_URL}/fastfind/notebookTemplate?${paramString}`,

    findUOMTypesAPI: (paramsString: string): string =>
      `${API_URL}/fastfind/uomTypes?${paramsString}`,

    getOrderNotesAPI: (miLoc: string, ocn: string, paramString: string) =>
      `${API_URL}/order/${miLoc}/${ocn}/notebook/search?${paramString}`,

    deleteOrderItemAPI: () => `${API_URL}/order/items/delete`,

    getOrderNoteAPI: (miLoc: string, ocn: string, paramString: string) =>
      `${API_URL}/order/${miLoc}/${ocn}/notebook?${paramString}`,

    getOrderLineStatusAPI: (
      miLoc: string,
      orderCtlNo: string,
      orderLineNo: string
    ) => `${API_URL}/order/${miLoc}/${orderCtlNo}/${orderLineNo}/status`,

    addEditOrderNoteAPI: (miLoc: string, ocn: string) =>
      `${API_URL}/order/${miLoc}/${ocn}/notebook`,

    checkForDuplicatesPOAPI: () => `${API_URL}/order/checkForDuplicatesPO`,

    checkCarrierAccountAPI: (paramsString: string) =>
      `${API_URL}/order/checkCarrierAccountRequiredNoSave?${paramsString}`,

    updateHeaderAPI: () => `${API_URL}/order/updateHeader`,

    findPriceHistoryAPI: (paramString: string) =>
      `${API_URL}/order/pricingHistory?${paramString}`,

    cancelWorkOrderAPI: (): string => `${API_URL}/order/cancelWorkOrder`,
    // #endregion

    // #region inventory
    findStartCustomerAPI: (paramsString: string): string =>
      `${API_URL}/inventory/startcustomers?${paramsString}`,

    shippingCustomersAPI: (paramsString: string): string =>
      `${API_URL}/inventory/startgroups?${paramsString}`,

    submitInventoryPlanAndGroupsAPI: (paramsString: string): string =>
      `${API_URL}/inventory/countgroup?${paramsString}`,

    deleteCountGroupAPI: (countPlanId: string): string =>
      `${API_URL}/inventory/countgroup/${countPlanId}`,

    submitInventoryOrderAPI: (): string =>
      `${API_URL}/inventory/countgroupitems`,

    findCountGroupsAPI: (paramsString: string): string =>
      `${API_URL}/inventory/countgroups?${paramsString}`,

    countGroupItemsAPI: (paramsString: string): string =>
      `${API_URL}/inventory/countgroupitems?${paramsString}`,

    getCountPlanOptionsAPI: (countPlanId: string): string =>
      `${API_URL}/inventory/countplanoption/${countPlanId}`,

    verifyManualItemAPI: (): string =>
      `${API_URL}/inventory/validateSingleManualItem`,

    unassignCountPlanAPI: (
      countPlanId: string,
      groupId: string,
      miLoc: string
    ): string =>
      `${API_URL}/inventory/countgroup/${countPlanId}/${groupId}/${miLoc}/unassignprimary`,
    // #endregion
  };
};

export default useAPIUrl;

export const authBulletinsAPI = (paramsString: string): string =>
  `${BULLETINS_URL}/posts?${paramsString}`;

export const findBulletinsAPI = (paramsString: string): string =>
  `${BULLETINS_URL}/posts?${paramsString}`;

export const getBulletinAPI = (id: string): string =>
  `${BULLETINS_URL}/posts/${id}`;
