import {
  activitiesURL,
  activityDetailURL,
  shareActivityByEmailURL,
  activityAddEditNoteURL,
} from 'navigation';
import { concatRoutes } from 'utils/navigations';

export const goToActivity = ({
  miLoc = '',
  customerId = '',
  userId = '',
  historyId = '',
  activityType = '',
  commentId = '',
}) => {
  const commentUrl = commentId ? `?commentId=${commentId}` : '';
  let route = concatRoutes(
    activitiesURL(),
    activityDetailURL(miLoc, customerId, userId, historyId, activityType) +
      commentUrl
  );
  if (commentUrl) {
    route += '&refresh=true';
  }
  return route;
};

export const goToShareActivityByEmail = ({
  miLoc = '',
  customerId = '',
  userId = '',
  historyId = '',
  activityType = '',
  reset = false,
}) =>
  concatRoutes(
    activitiesURL(),
    activityDetailURL(miLoc, customerId, userId, historyId, activityType),
    shareActivityByEmailURL(reset)
  );

export const goToActivityAddEditNoteURL = ({
  miLoc = '',
  customerId = '',
  userId = '',
  historyId = '',
  eventNoteId = '',
}): string =>
  concatRoutes(
    activitiesURL(),
    activityAddEditNoteURL(miLoc, customerId, historyId, userId, eventNoteId)
  );

export const goToShareActivityNoteByEmail = ({
  miLoc = '',
  customerId = '',
  userId = '',
  historyId = '',
  eventNoteId = '',
  reset = false,
}): string =>
  concatRoutes(
    activitiesURL(),
    activityAddEditNoteURL(miLoc, customerId, historyId, userId, eventNoteId),
    shareActivityByEmailURL(reset)
  );
