import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { first, isEmpty, isObject, size, toString } from 'lodash';
import { IonCol, IonRow, IonImg } from '@ionic/react';
import ItemDetailLines from 'common/components/List/ItemDetailLines';
import ShopModal from 'common/components/ShopModal/ShopModal';
import type {
  BaseComponentProps,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import { and, ifRender } from 'common/utils/logicHelpers';
import { OcnLineItemBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import type {
  Product,
  ProductDetailURLParams,
} from 'ProductSearchApp/models/Products';
import {
  getItemShopSourcing,
  isZcodedItem,
} from 'ProductSearchApp/util/ocnHelpers';
import { removeLeadingZeros } from 'utils/number';
import { goToProductDetail } from 'navigation/navigationHelpers';
import MIIcon from 'assets/MIIcon.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ProductHeader.module.scss';
import ProductPrice from './ProductPrice';

interface ProductHeaderProps extends BaseComponentProps {
  productData?: Product;
  withLink?: boolean;
  disabled?: boolean;
  hidePrice?: boolean;
  hideImage?: boolean;
  lineNumber?: string;
  showRepairDetails?: boolean;
  badge?: OptionalRenderProp<React.ComponentProps<typeof OcnLineItemBadge>>;
}

const ProductHeader = ({
  className,
  withLink = false,
  disabled,
  hidePrice = false,
  hideImage = false,
  lineNumber,
  productData,
  testid,
  showRepairDetails,
  ...props
}: ProductHeaderProps): JSX.Element => {
  const [img, setImage] = useState<string>(MIIcon);
  const [repairShopModalOpen, setRepairShopModalOpen] = useState(false);
  const { miLoc, id: customerNo } = useParams<ProductDetailURLParams>();
  const isZCoded = isZcodedItem(productData?.itemNumber);
  const shopLocations = getItemShopSourcing(
    productData as unknown as CartOrderItem
  );
  const firstShopLocation = first(shopLocations);
  const { t } = useTranslation();
  const badgeProps = isObject(props.badge) ? props.badge : undefined;
  const badgeText = isObject(props.badge) ? props.badge.status : props.badge;

  useEffect(() => {
    if (productData?.imageURL) {
      setImage(
        `${toString(process.env.REACT_APP_IMAGES_URL)}${toString(
          productData?.imageURL
        )}`
      );
    }
  }, [productData?.imageURL]);

  return (
    <div
      className={classNames(classes.productHeader, className)}
      data-testid={testid}
    >
      <IonRow class="ion-no-padding">
        {(!hideImage || lineNumber) && (
          <IonCol
            class="ion-no-padding"
            className={classNames(classes.imgCol, {
              [classes.lineNumberCol]: !isEmpty(lineNumber),
            })}
          >
            {lineNumber && (
              <Text
                className={classes.lineNumber}
                variant="mipro-h3-headline"
                text={lineNumber}
              />
            )}
            {!hideImage && !isZCoded && (
              <IonImg
                src={img}
                className={classes.img}
                onIonError={() => setImage(MIIcon)}
                data-testid="product-image"
              />
            )}
          </IonCol>
        )}
        <IonCol class="ion-no-padding">
          <IonRow className={classes.row}>
            <Text
              variant="content-small"
              text={toString(productData?.manufacturerName)}
              testid="product-manufacturerName"
              className={classes.mfrName}
            />

            {!isEmpty(badgeText) && (
              <OcnLineItemBadge
                status={toString(badgeText)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...badgeProps}
              />
            )}
          </IonRow>
          <IonRow>
            {!isZCoded && withLink ? (
              <Button
                className={classes.mfgPartNumber}
                text={toString(productData?.mfgPartNumber)}
                variant="link"
                textVariant="mipro-h3-headline"
                disabled={disabled}
                href={goToProductDetail({
                  miLoc,
                  customerNo,
                  itemNo: productData?.itemNumber,
                })}
                testid="product-link"
              />
            ) : (
              <>
                {isZCoded && (
                  <Badge
                    className={classes.badge}
                    text="Z"
                    type="info"
                    testid="zcoded-badge"
                    circle
                  />
                )}
                <Text
                  className={classes.mfgPartNumberLabel}
                  variant="mipro-h3-headline"
                  text={toString(productData?.mfgPartNumber)}
                  testid="product-manufacturerPartNo"
                />
              </>
            )}
          </IonRow>
          <ItemDetailLines
            className={classes.itemWrapper}
            lines={[
              {
                hidden: !isZCoded,
                label: t('productSearch:ocn:item'),
                value: removeLeadingZeros(productData?.itemNumber),
              },
              {
                label: t('productSearch:ocn:mino'),
                value: toString(productData?.mino),
              },
              {
                hidden: !isEmpty(productData?.customerStockNumber),
                label: t('product:csn'),
                value: toString(productData?.customerStockNumber),
              },
            ]}
          />
          {ifRender(
            showRepairDetails,
            <>
              {ifRender(
                and(size(shopLocations) === 1, !!firstShopLocation),
                <IonRow className={classes.showRepairDetails}>
                  <IonCol className={classes.ionCol}>
                    <div className={classes.labelAndField}>
                      <Text
                        className={classes.infoText}
                        variant="list-item-secondaryText"
                        text={t('productSearch:ocn:workOrder').split(':')[0]}
                        textQuery={
                          t('productSearch:ocn:workOrder').split(':')[0]
                        }
                      />
                      <Text
                        className={classes.infoText}
                        variant="list-item-secondaryText"
                        text={firstShopLocation?.ctlNo}
                      />
                    </div>
                    <div className={classes.labelAndField}>
                      <Text
                        className={classes.infoText}
                        variant="list-item-secondaryText"
                        text={t('productSearch:ocn:repairShop').split(':')[0]}
                        textQuery={
                          t('productSearch:ocn:repairShop').split(':')[0]
                        }
                      />
                      <Button
                        variant="link"
                        testid={`repair-shop-name-${
                          testid ?? 'product-header'
                        }`}
                        text={`${toString(
                          firstShopLocation?.requestTo
                        )} - ${toString(firstShopLocation?.requestToName)}`}
                        textVariant="list-item-secondaryText"
                        className={classes.shopModalBtn}
                        onClick={() => {
                          setRepairShopModalOpen(true);
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              )}
              {ifRender(
                size(shopLocations) > 1,
                <IonRow className={classes.showRepairDetails}>
                  <IonCol className={classes.ionCol}>
                    <Text
                      className={classes.infoText}
                      variant="list-item-secondaryText"
                      text={t('productSearch:ocn:repairShop').split(':')[0]}
                      textQuery={
                        t('productSearch:ocn:repairShop').split(':')[0]
                      }
                    />
                    <Text
                      className={classes.infoText}
                      variant="list-item-secondaryText"
                      text={t('productSearch:ocn:multipleRepairShop')}
                    />
                  </IonCol>
                </IonRow>
              )}
            </>
          )}
          {!hidePrice && (
            <ProductPrice
              testid={toString(productData?.itemNumber)}
              productData={productData}
            />
          )}
        </IonCol>
      </IonRow>
      <ShopModal
        repairShopModalOpen={repairShopModalOpen}
        setRepairShopModalOpen={setRepairShopModalOpen}
        shop={{
          shopMiLoc: firstShopLocation?.requestTo,
          shopName: firstShopLocation?.requestToName,
          shopAddr1: firstShopLocation?.requestToAddr1,
          shopAddr2: firstShopLocation?.requestToAddr2,
          shopAddr3: firstShopLocation?.requestToAddr3,
          shopCity: firstShopLocation?.requestToCity,
          shopState: firstShopLocation?.requestToState,
          shopZip: firstShopLocation?.requestToZip,
          country: firstShopLocation?.countryCd,
          shopPhone: firstShopLocation?.requestToPhone,
          shopEmail: firstShopLocation?.requestToEmail,
        }}
        emailModalTestId="product-header-shop-send-email-modal"
      />
    </div>
  );
};

export default ProductHeader;
