import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import {
  IonCol,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react';
import { choose } from 'common/utils/logicHelpers';
import { FieldArray } from 'formik';
import useRemoveItemReplenishment from 'StoreroomsApp/api/useRemoveItemReplenishment';
import useUpdateReplenishmentQuantity from 'StoreroomsApp/api/useUpdateReplenishmentQuantity';
import QuantityInput from 'StoreroomsApp/components/ItemsPOUList/QuantityInput';
import type { ItemReplenishmentDTO } from 'StoreroomsApp/models/Replenishment';
import { useDebounce } from 'use-debounce';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ReplenishmentsList.module.scss';

interface ReplenishmentItemProps {
  item: ItemReplenishmentDTO;
  index: number;
  disabled?: boolean;
}

const ReplenishmentItem = ({
  item,
  index,
  disabled,
}: ReplenishmentItemProps): JSX.Element => {
  const canEdit = useAccessControls(AccessControlType.editItemReplenishment);
  const {
    storeroomNumber,
    customerStockNumber,
    itemDescription,
    orderQuantity,
    fixedOrderQuantity,
    error,
  } = item;

  const itemQuantity = toNumber(orderQuantity);
  const [quantity, setQuantity] = useState(itemQuantity);
  const [updateQuantity] = useDebounce(quantity, 1000);

  const { onUpdateReplenishmentQuantity } = useUpdateReplenishmentQuantity();
  const { onRemoveItemReplenishment } = useRemoveItemReplenishment();

  useEffect(() => {
    setQuantity(itemQuantity);
  }, [itemQuantity]);

  useEffect(() => {
    if (updateQuantity !== itemQuantity) {
      onUpdateReplenishmentQuantity({
        itemId: toNumber(item.id),
        quantity: toNumber(
          choose(updateQuantity, updateQuantity, fixedOrderQuantity)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, itemQuantity, updateQuantity]);

  return (
    <FieldArray name="items">
      {(arrayHelpers) => (
        <IonItemSliding>
          <ActionRow
            disabled={false}
            className={classes.item}
            onClick={() => {
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
            testid={`item-${item.id}`}
          >
            <IonCol>
              <IonRow>
                <div className={classes.dataRow}>
                  <IonRow>
                    <Text text={storeroomNumber} variant="content-small" />
                    <Text
                      text={customerStockNumber || '<No CSN>'}
                      variant="content-small"
                    />
                  </IonRow>
                  <Text text={itemDescription} variant="content-heavy" />
                </div>
                <QuantityInput
                  className={classes.input}
                  buttonClassName={classes.buttonInput}
                  disabled={!canEdit || disabled}
                  value={quantity}
                  setValue={(v) => setQuantity(toNumber(v))}
                />
              </IonRow>
              {error && <Text className={classes.itemError} text={error} />}
            </IonCol>
          </ActionRow>
          <IonItemOptions side="end">
            <IonItemOption
              onClick={() => {
                arrayHelpers.remove(index);
                onRemoveItemReplenishment({ item });
              }}
              color="danger"
            >
              <Button
                className={classes.deleteButton}
                icon={findIcon('trash')}
                text="Delete"
                testid="delete-button"
              />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      )}
    </FieldArray>
  );
};

export default ReplenishmentItem;
